import React from "react";
import { useCallback, useRef, useState } from "react";
import FileUploadIcon from './FileUploadIcon';
import { Box, IconButton } from "@mui/material";
import group from "../../assets/group.svg";
type ChatMessageInput = {
  placeholder: string;
  accentColor: string;
  height: number;
  onSend?: (message: string) => void;
};

export const ChatMessageInput = ({
  placeholder,
  accentColor,
  height,
  onSend,
}: ChatMessageInput) => {
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSend = useCallback(() => {
    if (onSend && message.trim() !== "") {
      onSend(message);
      setMessage("");
    }
  }, [onSend, message]);

  const sendChat = (extractedText) => {
    if (onSend) {
      onSend(extractedText);
    }
  };

  

  
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={1.5}
      bgcolor="white"
      borderRadius={2}
      border={1}
      borderColor="rgba(0, 0, 0, 0.15)"
      sx={{ width: '100%' }}
    >
      <FileUploadIcon 
        sendChat={sendChat}  
        style={{
          width: `${height * 1.5}px`,
          height: `${height * 1.5}px`,
          marginRight: '8px',
          flexShrink: 0,
        }}
      />
      <textarea
        ref={inputRef as any}
        className="flex-1 px-4 py-2 text-gray-700 placeholder-gray-400 bg-white border-none focus:outline-none resize-none"
        style={{
          height: `${height}px`,
          minHeight: `${height}px`,
          maxHeight: `${height * 3}px`,
          fontSize: `${height * 0.35}px`,
          overflowY: 'auto',
          lineHeight: '1.5',
          width: '100%',
          flex: '1 1 auto',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word'
        }}
        placeholder={placeholder}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
      />
      <IconButton
        disabled={message.trim().length === 0 || !onSend}
        onClick={handleSend}
        sx={{ flexShrink: 0 }}
      >
        <img 
          src={group} 
          alt="Send"
          style={{ 
            width: `${height * 0.7}px`,
            height: `${height * 0.7}px`,
            color: "#0060C7" 
          }} 
        />
      </IconButton>
    </Box>
  );
};



