import * as React from "react";
import { Image } from "./Image";
import { ActionButton } from "./ActionButton";

export const ConfirmationMessage = ({ onClose, onAction , type}) => {
  const [isChatModalOpen, setIsChatModalOpen] = React.useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  
  // Define actions based on the type
  const actions = {
    Share: {
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/522741582e7729cc6edfd4ec91f4772fbca57031ff61bafd2c22cb6995324544?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Share",
      handleAction: () => {
        onAction("Share"); // Open InviteCollaborator modal
        onClose(); // Close ConfirmationMessage modal
      },
    },
    Chat: {
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/06d1ec34652a9423705a70f55f6e6ef860df040bf4c5c0f1da9f4b57e46041f9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Chat with an Expert",
      handleAction: () => {
        onAction("Chat"); // Open ExpertChat modal
        onClose(); // Close ConfirmationMessage modal
      },
    },
  };
  const selectedAction = actions[type];
  return (
    <div className="flex flex-col text-xl font-semibold rounded-none min-w-[793px] max-w-[793px] text-neutral-900">
      <div className="flex flex-col items-center px-7 pt-6 pb-20 w-full bg-white rounded-2xl max-md:px-5 max-md:max-w-full">
        {/* Close Button */}
        <button
          className="self-end w-6 h-6 cursor-pointer"
          onClick={onClose}
          aria-label="Close"
        >
          <span className="text-xl font-semibold">✕</span>
        </button>
        <Image
          src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/37915bbd5eb489794ef46b88f0e9fe90f7b41ce46f42f710b1734ceae23e800a?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
          alt="Success confirmation icon"
          className="mt-8 max-w-full aspect-square w-[118px]"
        />
        <h1 className="mt-9 text-3xl max-md:max-w-full max-sm:pl-6">
          Connection with an Expert Confirmed!
        </h1>
        <p className="mt-8 text-neutral-500 max-md:max-w-full max-sm:pl-7 px-4">
          An email has been sent to your expert with further instructions.
        </p>
        <p className="mt-12 text-center max-md:mt-10 max-md:max-w-full">
          Meanwhile, get insights by sharing with someone you know.
        </p>
        <div className="shrink-0 self-stretch mt-16 w-full h-px max-md:mt-10 max-md:mr-1" />

       {/* Action Button */}
             {selectedAction && (
               <ActionButton
                 text={selectedAction.text}
                 icon={selectedAction.icon}
                 onClick={()=> {selectedAction.handleAction(); onClose(); }}
               />
             )}
      </div>
    </div>
  );
};
