import React from "react";
import { ConnectionState } from "livekit-client";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ChatMessageType } from "../chat/ChatTile";
import { DisconnectButton } from "@livekit/components-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
interface PlaygroundHeaderProps {
  logo?: ReactNode;
  title?: ReactNode;
  githubLink?: string;
  height: number;
  accentColor: string;
  connectionState: ConnectionState;
  chat?: any;
  messages: ChatMessageType[];
  isAgentConnected: boolean;
  autoConnect: () => void;
  onConnectClicked: () => void;
  onCloseRoom?: () => void;
}

export const PlaygroundHeader = ({
  accentColor,
  height,
  connectionState,
  autoConnect,
  chat,
  messages,
  isAgentConnected,
  onConnectClicked,
  onCloseRoom,
}: PlaygroundHeaderProps) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = React.useState(600); 
  const disconnectButtonRef = React.useRef<HTMLButtonElement>(null);

  // Automatically connect on mount
  React.useEffect(() => {
    if (connectionState !== ConnectionState.Connected && isAgentConnected) {
      autoConnect();
    }
  }, [connectionState, isAgentConnected, autoConnect]);

  // Timer effect
  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          // End session when time is up
          handleEndSession();
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Function to handle ending the session
  const handleEndSession = () => {
    if (disconnectButtonRef.current) {
      disconnectButtonRef.current.click();
    } 
  };

  // Add new CSS class for blinking effect
  const timerClassName = `flex items-center gap-2 border rounded px-4 py-3 mx-10 mt-10 ${
    timeLeft <= 60 ? 'blink-warning' : 'border-gray-[#6B6B6B]'
  }`;

  // Modify formatTime to not include warning in the timer display
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs} min left`;
  };

  // Add the CSS using style tag
  React.useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes borderBlink {
        0% {
          border-color: #d1d5db;
        }
        50% {
          border-color: #C70003;
        }
        100% {
          border-color: #d1d5db;
        }
      }

      .blink-warning {
        animation: borderBlink 1s infinite;
        border-width: 1px;
      }
    `;
    document.head.appendChild(style);

    // Cleanup
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div
      className={`flex justify-between items-center shrink-0`}
      style={{
        height: height + "px",
        backgroundColor: `var(--${accentColor}-500)`,
      }}
    >
      <div className="flex items-center relative">
        <div className={timerClassName} style={{ color: timeLeft <= 60 ? '#C70003' : 'inherit' }}>
          <AccessTimeIcon/><span>{formatTime(timeLeft)}</span>
        </div>
        {timeLeft <= 60 && (
          <div className="absolute -bottom-5 left-1/2 -translate-x-1/2 whitespace-nowrap text-[#C70003] font-bold text-[10px]">
            ⚠️ Time is running out!
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 border border-red-500 text-[#C70003] mx-10 mt-10">
        {connectionState === ConnectionState.Connected && (
          <DisconnectButton
            stopTracks={true}
            ref={disconnectButtonRef}
            onClick={() => {
              onCloseRoom && onCloseRoom();
            }}
          >
            End Session
          </DisconnectButton>
        )}
      </div>
    </div>
  );
};
