import * as React from "react";

export const ExpertCard = ({ imageSrc, name, role, desc }) => {
    return (
      <div className="flex flex-col max-md:mt-7">
        <div className="group flex flex-col items-center justify-center w-[180px] h-[140px] mx-4 rounded-lg border border-solid border-zinc-100 shadow-[0px_2px_8px_rgba(182,191,200,0.3)] transition-all duration-300">
          <img
            loading="lazy"
            src={imageSrc}
            alt={`Profile picture of ${name}`}
            className="object-contain w-16 h-16 group-hover:hidden"
          />
          <div className="text-lg font-semibold text-neutral-900 text-center group-hover:hidden">
            {name}
          </div>
          <div className="text-sm font-normal text-neutral-500 text-center group-hover:hidden">
            {role}
          </div>
  
          {/* Hover content */}
          <div className="hidden group-hover:flex flex-col items-center">
            <div className="text-lg font-semibold text-neutral-900">{name}</div>
            <div className="text-sm font-normal text-neutral-500">{role}</div>
            <p className="mt-2 text-xs text-neutral-500 text-center">
              {desc}
            </p>
          </div>
        </div>
      </div>
    );
  };
  