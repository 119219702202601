import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import SharedProjectCard from "../components/RQPage/SharedProjectCard";
import { LastUpdated } from "../components/RQPage/LastUpdated";
import { CollaborationSection } from "../components/RQPage/CollaborationSection";
import Prdimage from "../assets/prdbg.png";
import Loader from "../assets/loader.gif";
import Slideimage from "../assets/slidebg.png";
import config from "../url_config/urls_config";
import Header from "../components/Common/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Deck from "./Deck"; // Import Deck component

const caiBaseUrl = config.getCaiBaseUrl();
const BASE_URL = config.getRq_api();
const RqPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { room_name = localStorage.getItem("room_name") } =
    location.state || {};
  const regenerate_prd = location.state?.regenerate_prd ?? false;
  const { name = localStorage.getItem("name") } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isPrdGenerating, setisPrdGenerating] = useState(false);
  const [isDeckGenerating, setisDeckGenerating] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null); // Store project details
  const [prdContent, setPrdContent] = useState(null);
  const [isDeckModalOpen, setIsDeckModalOpen] = useState(false); // State for modal
  const [sharedUsers, setSharedUsers] = useState([]);
  const [sharedProjects, setSharedProjects] = useState([]);
  const [activeSlideContent, setActiveSlideContent] = useState(null); 

  const documents = [
    { id: 1, title: "PRD", status: "generating", icon: "2" },
    { id: 2, title: "Deck", status: "generating", icon: "4" },
    { id: 3, title: "Coming soon", status: "coming-soon", icon: "6" },
    { id: 4, title: "Coming soon", status: "coming-soon", icon: "7" },
    { id: 5, title: "Coming soon", status: "coming-soon", icon: "8" },
  ];

  const fetchProjectDetails = async () => {
    try {
      
      setIsLoading(true);
      console.log("fetch project called");
      const response_project = await axios.get(
        `${BASE_URL}/api/projects/${room_name}`
      );
      const fetchedProjectDetails = response_project.data;
      console.log("project details fetched", response_project.data);
      setProjectDetails(fetchedProjectDetails); // Store project details in state
      // Fetch shared projects if fetchedProjectDetails.id exists
      if (fetchedProjectDetails.id) {
        const sharedProjectsResponse = await axios.get(
          `${BASE_URL}/api/fetch_shared_projects/${fetchedProjectDetails.id}`
        );
        console.log("shared projects fetched", sharedProjectsResponse.data);
        setSharedProjects(sharedProjectsResponse.data);

        // Handle the shared projects data as needed
      }
    } catch (error) {
      console.error("Error fetching project details:", error);

      // Check if the error response status is 404
      if (error.response && error.response.status === 404) {
        handleGeneratePRD(); // Call handleGeneratePRD if 404 error occurs
      } else {
        // Handle other errors if necessary
        console.error("An unexpected error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Function to generate PRD using the saved conversation data
  const handleGeneratePRD = () => {
    if (
      projectDetails?.prd &&
      projectDetails?.slide_content &&
      !regenerate_prd
    ) {
      console.log("no project moving forward");
      setPrdContent(projectDetails.prd);
      console.log("PRD already exists.");
      return;
    }

    console.log("started generation");
    setisPrdGenerating(true);
    setisDeckGenerating(true);

    const requestData = {
      room_name: room_name,
    };

    console.log("this is request data", requestData);

    // First API call: Generate PRD
    axios
      .post(`${BASE_URL}/api/generate-prd/`, requestData)
      .then((response_prd) => {
        console.log("prd generated", response_prd);
        toast.success("PRD Generated Successfully..", {
          position: "top-right", // Position the toast
          autoClose: 5000, // Time for which the toast is visible
          hideProgressBar: false, // Show progress bar
          closeOnClick: true, // Close the toast when clicked
          pauseOnHover: true, // Pause toast on hover
          draggable: true, // Make the toast draggable
          progress: undefined,
          style: {
            backgroundColor: "#4caf50", // Background color
            color: "#fff", // Text color
            borderRadius: "8px", // Rounded corners
            padding: "10px", // Padding inside the toast
            fontSize: "16px", // Font size
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Box shadow
          },
        });

        // Assuming the PRD content is returned in the response data
        setPrdContent(response_prd.data.prd);
        setisPrdGenerating(false);

        // Now, make the second API call with the data from the first API call
        const slideApiUrl = `${BASE_URL}/api/slides/${room_name}`;
        return axios.post(slideApiUrl, {
          prd_content: response_prd.data.prd, // Send PRD content in the body
        });
      })
      .then((slideResponse) => {
        console.log("Slide response received", slideResponse);
        toast.success("Deck Generated Successfully..", {
          position: "top-right", // Position the toast
          autoClose: 5000, // Time for which the toast is visible
          hideProgressBar: false, // Show progress bar
          closeOnClick: true, // Close the toast when clicked
          pauseOnHover: true, // Pause toast on hover
          draggable: true, // Make the toast draggable
          progress: undefined,
          style: {
            backgroundColor: "#4caf50", // Background color
            color: "#fff", // Text color
            borderRadius: "8px", // Rounded corners
            padding: "10px", // Padding inside the toast
            fontSize: "16px", // Font size
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Box shadow
          },
        });
        setisDeckGenerating(false);
        // Handle the slide response data if needed
      })
      .catch((error) => {
        console.error("Error generating PRD or fetching slide:", error);

        if (error.response) {
          // Handle the error response from the backend
          if (error.response.status === 400) {
            toast("Conversation data is insufficient for generating PRD.");
          } else {
            toast("An error occurred while generating PRD.");
          }
        } else {
          // If no response from the server, handle network-related errors
          toast("Network error. Please try again later.");
        }
      })
      .finally(() => {
        setisPrdGenerating(false);
        setisDeckGenerating(false);
      });
  };

  // Handle PRD click
  const handlePrdClick = () => {
    navigate(`/prd/`);
  };
  const closeDeckModal = () => {
    setIsDeckModalOpen(false); // Close the modal
  };
  // Function to generate Deck (logs PRD content)
  const handleDeckClick = () => {
    setIsDeckModalOpen(true); // Open the modal
  };

  // Sequential execution of tasks
  useEffect(() => {
    const executeSequentially = async () => {
      await fetchProjectDetails();
    };

    executeSequentially();
  }, []);

  const handleContinueChat = () => {
    navigate(`/brainstorm`);
  };

  const fetchSharedUsers = async () => {
    if (!projectDetails?.id) return; // Ensure projectDetails is loaded
    try {
      const response = await axios.get(`${BASE_URL}/api/fetch_shared_users_expert/${projectDetails.id}/`);
      setSharedUsers(response.data); // Store shared users in state
      console.log("Shared users fetched successfully:", response.data);
    } catch (error) {
      console.error("Error fetching shared users:", error);
    }
  };
  
  const handleCardClick = (projectId) => {
    setActiveSlideContent(projectId); // Set the content for the active slide
    setIsDeckModalOpen(true); // Open the modal
  };
  // Pass this function to CollaborationSection via props
  useEffect(() => {
    if (projectDetails?.id) {
      fetchSharedUsers();
    }
  }, [projectDetails]);

  return (
    <div className="bg-white min-h-screen">
  <ToastContainer />
  <Header />
  <main className="xl:px-32 xl:py-8 lg:px-24 lg:py-6 md:px-16 md:py-4 px-4 py-2">
    {isLoading ? (
      <div className="flex justify-center items-center h-full">
        <img src={Loader} alt="Loading" className="w-16 h-16" />
      </div>
    ) : (
      <>
        <h1 className="text-3xl text-black font-robo font-semibold">
          My Workspace
        </h1>
        {projectDetails ? (
          <div className="pt-5 font-robo text-base pb-8 text-neutral-500">
            <p>
              <strong className="text-black">
                {projectDetails.name} : 
              </strong>{" "}
              {projectDetails.description}
            </p>
          </div>
        ) : (
          <p>No project details available.</p>
        )}

        {/* Conversations Section */}
        <div className="flex flex-col text-base rounded-none mb-6 sm:mb-8">
      <div className="flex flex-wrap gap-5 justify-between items-center p-6 w-full bg-white rounded-2xl border border-solid border-zinc-100 shadow-[0px_2px_8px_rgba(182,191,200,0.3)] max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 self-stretch text-2xl font-semibold whitespace-nowrap text-neutral-900">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/676001c1529f67bd3f197240918190ce2450aac606accc1e6544a5250271b15b?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
            alt=""
            className="object-contain shrink-0 w-8 rounded-none aspect-square"
          />
          <div className="my-auto basis-auto">Conversations</div>
        </div>
        
<div className={`flex gap-1.5 self-stretch my-auto text-neutral-900 text-opacity-50`}>
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/66920a971c2864ffb8a2b99bb757388fd7e6aee7228754dcf730c9ab94c93f15?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
      alt=""
      className="object-contain shrink-0 w-6 aspect-square"
    />
    <div className="my-auto">10 mins</div>
  </div>
        <button
              className="flex gap-0 sm:gap-2 mx-14 sm:mx-0 self-stretch my-auto font-medium text-sky-600"
              onClick={() => {
                handleContinueChat();
              }}
            >
              <div className="grow my-auto">Continue Conversation</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/f583f3ec862db5ac04ae5ad0d094481241bca2f13d4956ef3d80801ac99ee273?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                alt=""
                className="object-contain shrink-0 w-6 aspect-square"
              />
            </button>
      </div>
    </div>

        {/* Documents Section */}
<div className="w-full border border-zinc-100 bg-white bg-opacity-50 rounded-2xl shadow-[0px_2px_8px_rgba(182,191,200,0.3)]">
  <div className="flex justify-between items-center px-6 pt-6 ">
    <div className="flex items-center gap-2">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/d51d0edab69e21ea949994e2c0a55bb404c84d7dcc2c8d2ee81690a187c3e0e3?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
        alt="Documents Icon"
        className="object-contain w-8 aspect-square"
      />
      <div className="text-2xl font-semibold mx-3 text-neutral-900">Documents</div>
    </div>
    <div className="text-sm text-gray-500">Last updated 3min ago</div>
  </div>

  {/* Horizontal scrollable section on small devices */}
<div
  className={`
    items-center
    justify-center
    flex 
    gap-1 
    overflow-x-auto 
    pt-4 
    px-7 
    py-4
    no-scrollbar 
    whitespace-nowrap 
    scroll-padding-left-7
    sm:flex 
    sm:gap-5 
    sm:overflow-x-auto 
    sm:py-4 
    sm:px-6 
    sm:ms-6
    sm:no-scrollbar 
    sm:whitespace-nowrap 
    md:flex 
    md:gap-5 
    md:overflow-x-auto 
    md:py-4 
    md:px-0 
    md:ms-3
    md:no-scrollbar 
    md:whitespace-nowrap 
    lg:flex 
    lg:gap-5 
    lg:overflow-x-auto 
    lg:py-6 
    lg:px-6 
    lg:ms-32
    lg:me-32
    lg:no-scrollbar 
    lg:whitespace-nowrap 
    xl:grid xl:grid-cols-3 xl:pt-7 xl:py-8 xl:ms-32 xl:me-32 xl:gap-10 
    gap-[20px]
  `}
  style={{
    gap: window.innerWidth <= 1388 && window.innerWidth >= 1280 ? "10px" : "",
  }}
>

      
      <div className="flex-shrink-0 sm:inline-block w-5/12 xs:w-3/6 sm:w-[40%] md:w-[30%] lg:w-[300px] ms-32 sm:ms-32 md:ms-0 md:me-3 sm:px-3 px-0">
        <div
          className={`
            w-full 
            sm:h-[160px] 
            h-[100px]
            flex 
            items-center 
            justify-center 
            border 
            border-gray-200 
            overflow-hidden 
            rounded 
            ${isPrdGenerating ? "cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() => !isPrdGenerating && handlePrdClick()}
        >
          <img src={Prdimage} alt="PRD" className="w-full h-full object-cover" />
          {isPrdGenerating && (
            <img
              src={Loader}
              alt="Generating PRD"
              className="absolute inset-0 w-40 h-50"
              style={{
                top: "8%",
                left: "18%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </div>
        <div className="mt-2 text-sm text-center text-neutral-900">PRD</div>
      </div>

   
      <div className="flex-shrink-0 sm:inline-block w-5/12 sm:w-[40%] md:w-[30%] lg:w-[300px] sm:px-3 px-0">
        <div
          className={`
            w-full 
            sm:h-[160px] 
            h-[100px]
            flex 
            items-center 
            justify-center 
            border 
            border-gray-200 
            overflow-hidden 
            rounded 
            ${isDeckGenerating ? "cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() => !isDeckGenerating && handleDeckClick()}
        >
          <img src={Slideimage} alt="Deck" className="w-full h-full object-cover" />
          {isDeckGenerating && (
            <img
              src={Loader}
              alt="Generating Deck"
              className="absolute inset-0 w-40 h-50"
              style={{
                top: "8%",
                left: "18%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </div>
        <div className="mt-2 text-sm text-center text-neutral-900">Deck</div>
      </div>

    
      <div className="flex-shrink-0 sm:inline-block w-5/12 sm:w-[40%] md:w-[30%] lg:w-[300px] sm:px-3 px-0">
          <div
            className="
              w-full 
              sm:h-[160px] 
              h-[100px]
              flex 
              items-center 
              justify-center 
              border 
              border-gray-200 
              rounded
            "
          >
            <div className="w-16 h-16 rounded-lg bg-gray-100 flex items-center justify-center ">
              <div className="text-6xl font-medium">+</div>
            </div>
          </div>
        <div className="mt-2 text-sm text-center text-gray-500">Request More</div>
      </div>
    </div>    
  </div>

{sharedUsers.length > 0 ? (
  <div className="mt-8 mb-16">
    <h2 className="text-xl font-semibold">Shared Users</h2>

    {/* Display table for larger screens */}
    <div className="hidden sm:block">
      <div className="rounded-lg border border-gray-300 overflow-hidden">
        <table className="table-auto border-collapse w-full text-center text-sm">
          <thead className="text-gray-600 bg-gray-100">
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2 border-l">Time</th>
              <th className="px-4 py-2 border-l">Role</th>
              <th className="px-4 py-2 border-l">Status</th>
            </tr>
          </thead>
          <tbody>
            {sharedUsers.map((user, index) => (
              <tr
                key={index}
                className={`hover:bg-gray-100 ${
                  index === sharedUsers.length - 1
                    ? "last:rounded-bl-lg last:rounded-br-lg"
                    : ""
                }`}
              >
                <td className="px-4 py-2 flex items-center">
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/b8b6abad549413e72104e38126323dd03c7a77cb5a3052edf755d909cc892ab9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                    alt="User Icon"
                    className="w-6 h-6 mr-4 bg-slate-400 rounded-full"
                  />
                  {user.name}
                </td>
                <td className="px-4 py-2 border-l">{user.time}</td>
                <td className="px-4 py-2 border-l">{user.role}</td>
                <td className="px-4 py-2 border-l">
                  {user.status === "unregistered" ? (
                    <span>Unregistered</span>
                  ) : user.status === "pending" ? (
                    <span>Pending</span>
                  ) : user.status === "verified" ? (
                    <span>Verified</span>
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    {/* Display dropdown for smaller screens */}
    <div className="block sm:hidden">
      {sharedUsers.map((user, index) => (
        <details
          key={index}
          className="mb-4 border border-gray-300 rounded-lg"
        >
          <summary className="flex items-center justify-between gap-4 px-4 py-2 bg-gray-100 cursor-pointer">
            <div className="flex items-center gap-4">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/b8b6abad549413e72104e38126323dd03c7a77cb5a3052edf755d909cc892ab9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
                alt="User Icon"
                className="w-6 h-6 bg-slate-400 rounded-full"
              />
              {user.name}
            </div>
            <span
              className="text-xl font-bold transition-transform duration-200"
            >
              v {/* Default icon */}
            </span>
          </summary>

          <div className="px-4 py-2 text-sm">
            <p>
              <strong>Time:</strong> {user.time}
            </p>
            <p>
              <strong>Role:</strong> {user.role}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              {user.status === "unregistered" ? (
                "Unregistered"
              ) : user.status === "pending" ? (
                "Pending"
              ) : user.status === "verified" ? (
                "Verified"
              ) : (
                "N/A"
              )}
            </p>
          </div>
        </details>
      ))}
    </div>
  </div>
) : (
  <p className="text-gray-500">No shared users available.</p>
)}


        <CollaborationSection
          projectId={projectDetails?.id}
          onCloseAction={fetchSharedUsers}
        />
      
      {sharedProjects?.length > 0 && (
            <div className="shared-projects-container">
              {sharedProjects.map((project) => (
                <SharedProjectCard
                  projectName={project.name} // Pass the name as a prop
                  description={project.description}
                  onClick={() => handleCardClick(project.id)} // Handle card click
                />
              ))}
              </div>
      )}
      </>
    )}
  </main>
  <Modal
        isOpen={isDeckModalOpen}
        onRequestClose={() => {
          closeDeckModal();
          // Ensure commentary stops when modal closes
          if (window.speechSynthesis) {
            window.speechSynthesis.cancel();
          }
        }}
        contentLabel="Deck Modal"
        className="w-[85%] h-[85%] max-w-none mx-auto bg-blue-200 p-8 rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20"
      >
        <button
          className="absolute top-4 right-4 text-white bg-red-600 p-3 rounded-full"
          onClick={() => {
            closeDeckModal();
            // Stop commentary on modal close
            if (window.speechSynthesis) {
              window.speechSynthesis.cancel();
            }
          }}
        >
          X
        </button>
        <Deck isDeckModalOpen = {isDeckModalOpen} projectId = {activeSlideContent}/>
      </Modal>
</div>

  );
};

export default RqPage;
