const config = {
  isDevelopment: false, // Toggle this to `false` for production

  urls: {
    development: {
      cai_base_url: "http://localhost:8001", // Development CAI base URL
      livekit_wss_url: "", // Development LiveKit WSS URL
      usecase_id: "",
      rq_api: "http://localhost:8000",
    },
    production: {
      cai_base_url: "https://fastapi-server-1081098542602.us-central1.run.app", // Production CAI base URL
      livekit_wss_url: "wss://jpmc-demo-r2888xe7.livekit.cloud",
      usecase_id: "f8eab78a-d65e-46cd-b2b5-e07bbe9a48d8",
      rq_api: "https://rq-api.think41.ai",
    },
  },

  getCaiBaseUrl() {
    return this.isDevelopment
      ? this.urls.development.cai_base_url
      : this.urls.production.cai_base_url;
  },

  getLivekitWssUrl() {
    return this.isDevelopment
      ? this.urls.development.livekit_wss_url
      : this.urls.production.livekit_wss_url;
  },
  getUsecaseID() {
    return this.isDevelopment
      ? this.urls.development.usecase_id
      : this.urls.production.usecase_id;
  },
  getRq_api() {
    return this.isDevelopment
      ? this.urls.development.rq_api
      : this.urls.production.rq_api;
  },
};

export default config;
