import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Switch } from "@mui/material";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const MicAndSpeaker = ({ getResponsiveSize, externalAudio, onMicToggle }) => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [masterGainNode, setMasterGainNode] = useState(null);
  const [audioLevel, setAudioLevel] = useState(0);
  const animationFrameRef = useRef();
  const analyserRef = useRef(null);
  const audioNodesRef = useRef(new Map());

  const createMicStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      
      const context = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = context.createAnalyser();
      const source = context.createMediaStreamSource(stream);
      
      analyser.fftSize = 1024;
      analyser.smoothingTimeConstant = 0.8;
      source.connect(analyser);
      analyserRef.current = analyser;
      
      const updateAudioLevel = () => {
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
        const normalizedLevel = Math.pow(average / 255, 0.7);
        setAudioLevel(Math.min(normalizedLevel * 2, 1));
        
        animationFrameRef.current = requestAnimationFrame(updateAudioLevel);
      };
      
      updateAudioLevel();
    } catch (error) {
      console.error("Error accessing media devices:", error);
      setIsMicOn(false);
    }
  };

  const stopMicStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
    }
    setAudioLevel(0);
  };

  const toggleMic = async () => {
    if (!isMicOn) {
      await createMicStream();
      setIsMicOn(true);
      if (onMicToggle) onMicToggle(true);
    } else {
      stopMicStream();
      setIsMicOn(false);
      if (onMicToggle) onMicToggle(false);
      
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(track => {
          track.stop();
          track.enabled = false;
        });
        
        if (navigator.permissions && navigator.permissions.revoke) {
          await navigator.permissions.revoke({ name: 'microphone' });
        }
      } catch (error) {
        console.error("Error stopping mic at browser level:", error);
      }
    }
  };

  // Add this function to check mic permissions
  const checkMicPermissions = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'microphone' });
      return result.state === 'granted';
    } catch (error) {
      console.error("Error checking mic permissions:", error);
      return false;
    }
  };

  // Add this effect to monitor permissions changes
  useEffect(() => {
    const permissionCheck = async () => {
      const hasPermission = await checkMicPermissions();
      if (!hasPermission && isMicOn) {
        setIsMicOn(false);
        stopMicStream();
      }
    };

    permissionCheck();
    
    // Set up permission change listener
    navigator.permissions?.query({ name: 'microphone' })
      .then(permissionStatus => {
        permissionStatus.onchange = () => permissionCheck();
      });
  }, []);

  useEffect(() => {
    const syncWithLiveKit = async () => {
      const hasPermission = await checkMicPermissions();
      if (hasPermission) {
        await createMicStream();
        setIsMicOn(true);
        if (onMicToggle) onMicToggle(true);
      }
    };

    syncWithLiveKit();
    
    return () => {
      stopMicStream();
      if (onMicToggle) onMicToggle(false);
    };
  }, []);

  // Toggle Speaker
  const toggleSpeaker = () => {
    setIsSpeakerOn(prevState => {
      const newState = !prevState;
      
      // Handle external audio if it exists
      if (externalAudio) {
        externalAudio.muted = !newState;
      }

      // Get all audio and video elements
      const mediaElements = [
        ...document.getElementsByTagName('audio'),
        ...document.getElementsByTagName('video')
      ];

      mediaElements.forEach(element => {
        // Direct muting/unmuting
        element.muted = !newState;
        
        try {
          // Handle Web Audio API connections
          if (!audioNodesRef.current.has(element) && audioContext) {
            const source = audioContext.createMediaElementSource(element);
            audioNodesRef.current.set(element, source);
            
            if (newState) {
              source.connect(audioContext.destination);
            }
          }

          const audioNode = audioNodesRef.current.get(element);
          if (audioNode) {
            if (newState) {
              audioNode.connect(audioContext.destination);
            } else {
              audioNode.disconnect();
            }
          }
        } catch (error) {
          console.warn('Audio processing error:', error);
        }
      });

      // Also handle any new audio/video elements that might be added later
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'AUDIO' || node.tagName === 'VIDEO') {
              node.muted = !newState;
            }
          });
        });
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });

      return newState;
    });
  };

  // Add effect to handle external audio mute state changes
  useEffect(() => {
    if (externalAudio) {
      externalAudio.muted = !isSpeakerOn;
    }
  }, [externalAudio, isSpeakerOn]);

  // Custom Mic Icon with visualization
  const CustomMicIcon = () => (
    <div style={{ position: 'relative', width: '24px', height: '24px' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '24px', height: '24px' }}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          {/* Background mic shape (outline) */}
          <path
            d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"
            fill="none"
            stroke="currentColor"
          />
          {/* Liquid fill using clip-path */}
          <path
            d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"
            fill="#1976d2"
            style={{
              clipPath: `inset(${100 - (audioLevel * 100)}% 0 0 0)`,
              transition: 'clip-path 0.05s linear',
              opacity: 0.8,
            }}
          />
          {/* Stand part of the mic */}
          <path
            d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"
            fill="currentColor"
          />
        </svg>
      </div>
      {!isMicOn && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0,
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            width: '2px',
            height: '24px',
            background: 'currentColor',
            transform: 'rotate(45deg)',
          }} />
        </div>
      )}
    </div>
  );

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: getResponsiveSize('10px', '12px', '15px', '18px'),
        width: '100%',
      }}
    >
      {/* Mic Control with custom icon */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <IconButton onClick={toggleMic} size="small">
          {isMicOn ? <CustomMicIcon /> : <MicOffIcon />}
        </IconButton>
        <Switch
          checked={isMicOn}
          onChange={toggleMic}
          size={getResponsiveSize('small', 'medium', 'medium', 'medium')}
          color="primary"
        />
      </Box>

      {/* Speaker Control */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <IconButton onClick={toggleSpeaker} size="small">
          {isSpeakerOn ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
        <Switch
          checked={isSpeakerOn}
          onChange={toggleSpeaker}
          size={getResponsiveSize('small', 'medium', 'medium', 'medium')}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default MicAndSpeaker;
