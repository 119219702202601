"use client";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slide from "../components/slides/Slide";
import SlideControls from "../components/slides/SlideControls";
import axios from "axios";
import config from "../url_config/urls_config.js";
import { Loader2, X, Play, Pause, Fullscreen } from "lucide-react";

const BASE_URL = config.getRq_api();

const Deck = ({isDeckModalOpen}) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const speechSynthesisRef = useRef(window.speechSynthesis);
  const utteranceRef = useRef(new SpeechSynthesisUtterance());

  useEffect(() => {
    fetchSlides();
  }, []);
  

  const fetchSlides = async () => {
    const roomName = localStorage.getItem("room_name");

    if (!roomName) {
      console.error("Room name not found in local storage");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/api/slides/${roomName}`);
      setSlides(response.data.slides);
      setIsLoading(false);

    } catch (error) {
      console.error("Error fetching slides:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prevSlide) => {
        const nextSlide = prevSlide + 1;
        if (isFullscreen) {
          stopCommentary();
          if (slides[nextSlide] && slides[nextSlide].voiceCommentary) {
            speakCommentary(slides[nextSlide].voiceCommentary);
          }
        }
        return nextSlide;
      });
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => {
        const prevSlideIndex = prevSlide - 1;
        if (isFullscreen) {
          stopCommentary();
          if (
            slides[prevSlideIndex] &&
            slides[prevSlideIndex].voiceCommentary
          ) {
            speakCommentary(slides[prevSlideIndex].voiceCommentary);
          }
        }
        return prevSlideIndex;
      });
    }
  };


  const speakCommentary = useCallback(
    (text) => {
      if (speechSynthesisRef.current && isFullscreen) {
        utteranceRef.current.text = text;
        speechSynthesisRef.current.speak(utteranceRef.current);
        setIsPlaying(true);
        setIsPaused(false);
      }
    },
    [isFullscreen]
  );

  const stopCommentary = useCallback(() => {
    if (speechSynthesisRef.current) {
      speechSynthesisRef.current.cancel();
      setIsPlaying(false);
      setIsPaused(false);
    }
  }, []);

  const pauseCommentary = useCallback(() => {
    if (speechSynthesisRef.current && isFullscreen) {
      speechSynthesisRef.current.pause();
      setIsPaused(true);
    }
  }, [isFullscreen]);

  const resumeCommentary = useCallback(() => {
    console.log(isFullscreen);
    console.log(speechSynthesisRef.current);
    
    if (speechSynthesisRef.current && isFullscreen) {
      speechSynthesisRef.current.resume();
      setIsPaused(false);
    }
  }, [isFullscreen]);

  const startPresentation = useCallback(() => {
    if (isFullscreen) {
      setIsPlaying(true);
      setCurrentSlide(0);
      console.log(slides[0].voiceCommentary);
      
      if (slides[0] && slides[0].voiceCommentary) {
        speakCommentary(slides[0].voiceCommentary);
      }
    }
  }, [slides, speakCommentary, isFullscreen]);

  const moveToNextSlide = useCallback(() => {
    if (currentSlide < slides.length - 1) {
      stopCommentary();
      setCurrentSlide((prevSlide) => {
        const nextSlide = prevSlide + 1;
        if (
          isFullscreen &&
          slides[nextSlide] &&
          slides[nextSlide].voiceCommentary
        ) {
          speakCommentary(slides[nextSlide].voiceCommentary);
        }
        return nextSlide;
      });
    } else {
      setIsPlaying(false);
    }
  }, [currentSlide, slides, stopCommentary, speakCommentary, isFullscreen]);

  useEffect(() => {
    utteranceRef.current.onend = () => {
      if (isPlaying && currentSlide < slides.length - 1 && isFullscreen) {
        moveToNextSlide();
      } else {
        setIsPlaying(false);
      }
    };
    return () => {
      utteranceRef.current.onend = null;
    };
  }, [isPlaying, currentSlide, slides.length, moveToNextSlide, isFullscreen]);

  const restartPresentation = useCallback(() => {
    if (isFullscreen) {
      stopCommentary();
      setCurrentSlide(0);
      setIsPlaying(true);
      if (slides[0] && slides[0].voiceCommentary) {
        speakCommentary(slides[0].voiceCommentary);
      }
    }
  }, [slides, stopCommentary, speakCommentary, isFullscreen]);

  useEffect(() => {
    console.log(isDeckModalOpen)
    if(isDeckModalOpen && slides[0] && slides[0].voiceCommentary){

        startPresentation();
    }
  }, [isDeckModalOpen, slides, startPresentation]);

  return (
    <div className="h-full flex flex-col bg-gradient-to-br from-blue-100 to-blue-300 z-50">
      {/* Modal content container */}
      <div className="flex flex-col flex-grow overflow-hidden">
        {isLoading && (
          <div className="fixed inset-0 bg-gradient-to-br from-gray-900 to-gray-800 bg-opacity-75 flex items-center justify-center z-50 backdrop-blur-md">
            <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl flex flex-col items-center max-w-sm w-full mx-4">
              <Loader2 className="h-16 w-16 animate-spin text-blue-500 dark:text-blue-400" />
              <p className="text-xl font-semibold text-gray-800 dark:text-gray-200 mt-6 mb-4 animate-pulse">
                Generating slides...
              </p>
            </div>
          </div>
        )}

        {!isLoading && slides.length > 0 ? (
          <>
            {/* Slide container */}
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="flex-grow mb-4 overflow-auto rounded-lg shadow-lg"
            >
              <Slide data={slides[currentSlide]} />
            </motion.div>

            {/* Controls */}
            <div className="mt-auto">
              <SlideControls
                currentSlide={currentSlide}
                totalSlides={slides.length}
                onNext={handleNextSlide}
                onPrev={handlePrevSlide}
                isFullscreen={isFullscreen}
                isPlaying={isPlaying}
                isPaused={isPaused}
                onStartPresentation={startPresentation}
                onPauseCommentary={pauseCommentary}
                onResumeCommentary={resumeCommentary}
                onRestartPresentation={restartPresentation}
              />
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-xl text-gray-600">No slides available.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Deck;
