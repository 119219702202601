import React, {useEffect} from "react";
import { ActionButton } from "./ActionButton";
import { ExpertChat } from "./ExpertChat";
import { InviteCollaborator } from "./InviteCollaborator";

export const CollaborationSection = ({ projectId , onCloseAction}) => {
  const [isChatModalOpen, setIsChatModalOpen] = React.useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);

  // Handle the action type from ConfirmationMessage
  const handleAction = (type) => {
    if (type === "Chat") {
      setIsChatModalOpen(true); // Open Chat Modal
      setIsShareModalOpen(false); // Ensure Share Modal is closed
      onCloseAction?.(); 
    } else if (type === "Share") {
      setIsShareModalOpen(true); // Open Share Modal
      setIsChatModalOpen(false); // Ensure Chat Modal is closed
      onCloseAction?.(); 
    }
  };
  useEffect(() => {
    if (isChatModalOpen || isShareModalOpen) {
      console.log("Modal state changed:", {
        isChatModalOpen,
        isShareModalOpen,
      });
      // Trigger any required action, e.g., calling a fetch function
    }
  }, [isChatModalOpen, isShareModalOpen]);

  const actions = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/522741582e7729cc6edfd4ec91f4772fbca57031ff61bafd2c22cb6995324544?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Share",
      onClick: () => setIsShareModalOpen(true),
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/06d1ec34652a9423705a70f55f6e6ef860df040bf4c5c0f1da9f4b57e46041f9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      text: "Chat with an Expert",
      onClick: () => setIsChatModalOpen(true),
    },
  ];

  return (
    <div className="flex flex-col rounded-none text-neutral-900 mt-6 sm:mt-8">
      <div className="flex flex-col px-10 py-6 w-full max-w-screen mx-auto rounded-2xl border border-solid bg-white bg-opacity-50 border-zinc-100 shadow-[0px_2px_8px_rgba(182,191,200,0.3)] max-md:px-5 max-md:max-w-full">
        <div className="text-xs sm:text-xl text-center font-robo font-normal max-md:max-w-full">
        Collaborate to share ideas and gain insights for your project.
        </div>
        <div className="flex flex-wrap gap-3 sm:gap-16 justify-center self-center mt-7 w-full text-base font-semibold">
          {actions.map((action, index) => (
            <ActionButton key={index} icon={action.icon} text={action.text} onClick={action.onClick} />
          ))}
        </div>
      </div>

      {/* Chat Modal */}
      {isChatModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative">
            <ExpertChat onClose={() => setIsChatModalOpen(false)} projectId={projectId} onAction={handleAction} />
          </div>
        </div>
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <div className="fixed inset-0 flex justify-center bg-black bg-opacity-50 z-50">
          <div className="w-[80%] max-w-4xl">
            <InviteCollaborator
              onClose={() => setIsShareModalOpen(false)}
              projectId={projectId}
              onAction={handleAction} // Pass the onAction handler
            />
          </div>
        </div>
      )}
    </div>
  );
};
