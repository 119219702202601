import React from "react";
import { ChatMessageType, ChatTile } from "../components/chat/ChatTile.tsx";
import {
  TrackReferenceOrPlaceholder,
  useChat,
  useLocalParticipant,
  useTrackTranscription,
} from "@livekit/components-react";
import {
  LocalParticipant,
  Participant,
  Track,
  TranscriptionSegment,
} from "livekit-client";
import { useEffect, useState } from "react";

export function TranscriptionTile({
  agentAudioTrack,
  accentColor,
  onMessagesUpdate,
  chat,
}: {
  agentAudioTrack: TrackReferenceOrPlaceholder;
  accentColor: string;
  onMessagesUpdate: (messages: ChatMessageType[]) => void;
  chat: { conversation: ChatMessageType[] };
}) {
  const agentMessages = useTrackTranscription(agentAudioTrack);
  const localParticipant = useLocalParticipant();
  const localMessages = useTrackTranscription({
    publication: localParticipant.microphoneTrack,
    source: Track.Source.Microphone,
    participant: localParticipant.localParticipant,
  });

  const [transcripts, setTranscripts] = useState<Map<string, ChatMessageType>>(
    new Map()
  );
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const { chatMessages, send: sendChat } = useChat();
  useEffect(() => {
    // Convert the chat messages to the expected format
    const convertedChatHistory = (chat?.conversation || []).map((msg: any) => ({
      name: msg.name,
      message: msg.content,
      timestamp: new Date(msg.timestamp).getTime(), // Convert ISO timestamp to milliseconds
      isSelf: msg.role === 'user', // 'user' messages are self, 'assistant' messages are not
    }));

    // Create a new array starting with converted chat history
    const allMessages = [...convertedChatHistory];

    const localName = localParticipant.localParticipant.name || "You";

    // Create a new Map for transcripts
    const newTranscripts = new Map(transcripts);
    
    agentMessages.segments.forEach((s) =>
      newTranscripts.set(
        s.id,
        segmentToChatMessage(
          s,
          newTranscripts.get(s.id),
          agentAudioTrack.participant,
          localName
        )
      )
    );

    localMessages.segments.forEach((s) => {
      newTranscripts.set(
        s.id,
        segmentToChatMessage(
          s,
          newTranscripts.get(s.id),
          localParticipant.localParticipant,
          localName
        )
      );
    });

    // Add transcripts
    allMessages.push(...Array.from(newTranscripts.values()));

    // Add chat messages
    for (const msg of chatMessages) {
      const isAgent = msg.from?.identity === agentAudioTrack.participant?.identity;
      const isSelf = msg.from?.identity === localParticipant.localParticipant.identity;
      let name = msg.from?.name;
      if (!name) {
        name = isAgent ? "Agent" : (isSelf ? "You" : "Unknown");
      }
      allMessages.push({
        name,
        message: msg.message,
        timestamp: msg.timestamp || Date.now(),
        isSelf: isSelf,
      });
    }

    // Sort messages by timestamp
    allMessages.sort((a, b) => a.timestamp - b.timestamp);

    // Update state
    setTranscripts(newTranscripts);
    setMessages(allMessages);
    onMessagesUpdate(allMessages);
  }, [
    chat?.conversation,
    chatMessages,
    localParticipant.localParticipant,
    agentAudioTrack.participant,
    agentMessages.segments,
    localMessages.segments,
    onMessagesUpdate,
  ]);

  return (
    <div className="flex justify-center w-full h-full overflow-hidden">
      <ChatTile messages={messages} accentColor={accentColor} onSend={sendChat} />
    </div>
  );
}

function segmentToChatMessage(
  s: TranscriptionSegment,
  existingMessage: ChatMessageType | undefined,
  participant: Participant,
  localName: string
): ChatMessageType {
  const msg: ChatMessageType = {
    message: s.final ? s.text : `${s.text} ...`,
    name: participant instanceof LocalParticipant ? localName : "Agent",
    isSelf: participant instanceof LocalParticipant,
    timestamp: existingMessage?.timestamp ?? Date.now(),
  };
  return msg;
}