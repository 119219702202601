import React, { useEffect, useCallback, useState } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  StartAudio,
} from "@livekit/components-react";
import Playground from "../components/playground/Playground.tsx";
import { ConfigProvider } from "../hooks/useConfig.tsx";
import {
  ConnectionMode,
  ConnectionProvider,
  useConnection,
} from "../hooks/useConnection.tsx";
import "@livekit/components-styles";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../url_config/urls_config.js";
import axios from "axios";

const caiBaseUrl = config.getCaiBaseUrl();
const livekitWssUrl = config.getLivekitWssUrl();
const secret_code = config.getUsecaseID();

export default function LiveKit() {
  return (
    <ConfigProvider>
      <ConnectionProvider>
        <div className="flex flex-col md:flex-row">
          <HomeInner />
        </div>
      </ConnectionProvider>
    </ConfigProvider>
  );
}

export function HomeInner() {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const name = localStorage.getItem("name");
  const room_name = localStorage.getItem("room_name");
  const { shouldConnect, mode, connect, disconnect } = useConnection();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resume_session, setresumesession] = useState(true);
  const [isRoomClosing, setIsRoomClosing] = useState(false);

  const fetchTranscription = async () => {
    try {
      setIsLoading(true);
      const response_conv = await axios.get(
        `${caiBaseUrl}/session/${room_name}/transcript/`
      );
      const fetchedConversations = response_conv.data.conversations;
      setConversations(fetchedConversations);
      console.log("Fetched conversations:", fetchedConversations);
    } catch (error) {
      console.error("Error fetching transcription:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTranscription();
  }, []);

  const handleConnect = useCallback(
    async (c: boolean, mode: ConnectionMode) => {
      c ? connect(mode) : disconnect();
    },
    [connect, disconnect]
  );

  const connectToRoom = useCallback(async () => {
    if (token || loading) return;
    setLoading(true);
    try {
      const requestBody = {
        room_name: room_name,
        name: name,
        secret_code: secret_code,
        resume_session: resume_session,
      };
      console.log("this is request body :", requestBody);

      const response = await fetch(`${caiBaseUrl}/getToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 403) {
        alert("Incorrect secret key. Please try again");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch token");
      }

      const data = await response.json();
      console.log("Token received:", data.token);
      setToken(data.token);
      handleConnect(true, process.env.LIVEKIT_WSS_URL ? "env" : mode);
    } catch (error) {
      console.error("Error fetching token", error);
    } finally {
      setLoading(false);
    }
  }, [token, loading, name, room_name, handleConnect, mode, navigate]);

  useEffect(() => {
    if (!token) {
      connectToRoom();
    }
  }, [connectToRoom, token]);

  const handleCloseRoom = useCallback(async () => {
    setIsRoomClosing(true);
    try {
      await disconnect();
      console.log("Room closed successfully.");
    } catch (error) {
      console.error("Error closing room:", error);
    } finally {
      setToken(null);
      navigate("/dashboard");
    }
  }, [disconnect, navigate]);

  return (
    <>
      <main className="relative flex flex-col justify-center px-4 items-center h-screen w-full bg-white">
        {token === null || isRoomClosing ? (
          <div style={{ color: "white" }}>Connecting...</div>
        ) : (
          <LiveKitRoom
            className="flex flex-col h-full w-full"
            serverUrl={livekitWssUrl}
            token={token}
            connect={shouldConnect}
            onError={(e) => {
              console.error(e);
            }}
          >
            <Playground
              themeColors={["cyan"]}
              onConnect={(c) => {
                const m = process.env.LIVEKIT_WSS_URL ? "env" : mode;
                handleConnect(c, m);
              }}
              chat={conversations}
              onCloseRoom={handleCloseRoom}
            />
            <RoomAudioRenderer />
            <StartAudio label="Click to enable audio playback" data-audio-controlled="true" />
          </LiveKitRoom>
        )}
      </main>
    </>
  );
}
