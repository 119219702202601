import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  Typography, 
  useMediaQuery, 
  useTheme 
} from "@mui/material";
import rafiki from "../components/IntertesialPage/rafiki.svg";
import { useNavigate, useLocation } from "react-router-dom";
import MicAndSpeaker from "../components/IntertesialPage/Mic_and_speaker";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import audioFile from '../assets/Hello and welcome Th.mp3';

export const IntertesialPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, room_name, chat } = location.state || {};
  const [imageSrc, setImageSrc] = useState(rafiki);
  const [text, setText] = useState("We're building your workspace.");
  const [showMicSpeakerBox, setShowMicSpeakerBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audioInstance, setAudioInstance] = useState(null);

  // Theme and responsive breakpoints
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  // Responsive sizing logic
  const getResponsiveSize = (xs, sm, md, lg) => {
    if (isXs) return xs;
    if (isSm) return sm;
    if (isMd) return md;
    return lg;
  };

  // Image and Text Cycling Effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageSrc === rafiki) {
        setImageSrc("https://c.animaapp.com/qM5VqOWz/img/visionary-technology-rafiki@2x.png");
        setText("Just a moment to get everything perfect!");
      } else {
        setImageSrc(rafiki);
        setText("We're building your workspace.");
      }
      setShowMicSpeakerBox(true);
    }, 1600);

    return () => clearInterval(intervalId);
  }, [imageSrc]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 4800); // Same duration as the loading animation

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    return () => {
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.currentTime = 0;
      }
    };
  }, [audioInstance]);

  const handleGetStarted = () => {
    navigate(`/brainstorm/`, {
      state: {
        name: name,
        room_name: room_name,
        chat: chat,
      },
    });
  };

  const playSound = () => {
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    }

    const audio = new Audio(audioFile);
    setAudioInstance(audio);

    const speakerElements = document.getElementsByTagName('audio');
    const isMuted = speakerElements.length > 0 ? speakerElements[0].muted : false;

    audio.muted = isMuted;

    audio.play();
  };

  return (
    <div
      style={{
        background: "linear-gradient(180deg, rgba(153, 181, 212, 1) 0%, rgba(221, 206, 231, 1) 100%)",
        width: "100vw",
        height: "100vh",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        padding: getResponsiveSize('10px', '20px', '30px', '40px'),
      }}
    >
      {/* Background effect */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "200%",
          height: "200%",
          background: "linear-gradient(45deg, var(--primary-color), var(--secondary-color))",
          animation: "moveBackground 10s infinite alternate",
          transform: "rotate(20deg)",
          zIndex: -1,
          opacity: 0.5,
        }}
      />

      {/* Main Content Container */}
      <Box
        sx={{
          width: '100%',
          maxWidth: getResponsiveSize('300px', '400px', '600px', '800px'),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: getResponsiveSize('10px', '15px', '18px', '20px'),
        }}
      >
        {/* Heading */}
        <Typography
          variant={getResponsiveSize('body1', 'h6', 'h5', 'h4')}
          align="center"
          sx={{
            fontFamily: "'Roboto', Helvetica",
            fontWeight: "normal",
            px: '15px',
            fontSize: getResponsiveSize('14px', '16px', '18px', '20px'),
          }}
        >
          {text}
        </Typography>

        {/* Loading Bar */}
        <Box 
          sx={{ 
            width: '100%', 
            px: getResponsiveSize('15px', '20px', '30px', '0'),
          }}
        >
          <Box
            sx={{
              border: '1px solid #e0e7f7',
              width: '100%',
              height: getResponsiveSize('12px', '14px', '16px', '18px'),
              borderRadius: '8px',
              bgcolor: 'white',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(90deg, rgb(117, 115, 214) 0%, rgb(0, 96, 199) 100%)",
                width: isLoading ? '0%' : '100%',
                height: '100%',
                borderRadius: '8px',
                animation: isLoading ? "loadingBar 4800ms linear forwards" : "none",
              }}
            />
          </Box>
        </Box>

        {/* Mic and Speaker Controls */}
        <MicAndSpeaker getResponsiveSize={getResponsiveSize} externalAudio={audioInstance} />

        {/* Play Sound Button */}
        <Button
          onClick={playSound}
          sx={{
            background: 'rgba(255, 255, 255, 0.4)',
            borderRadius: '5px',
            padding: '8px 16px',
            width: 'auto',
            minWidth: 'fit-content',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.6)',
              transform: 'scale(1.05)',
            },
            transition: 'all 0.2s ease',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <PlayCircleIcon sx={{ 
            fontSize: getResponsiveSize('20px', '22px', '24px', '26px'),
            color: '#0060C7'
          }} />
          <Typography sx={{ 
            color: '#0060C7',
            fontSize: getResponsiveSize('12px', '13px', '14px', '15px'),
          }}>
            Play test sound to ensure your speaker is working
          </Typography>
        </Button>

        {/* Mic Speaker Box */}
        {showMicSpeakerBox && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              gap: '20px',
              opacity: showMicSpeakerBox ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
              position: 'absolute',
              bottom: getResponsiveSize('40px', '50px', '60px', '70px'),
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {/* Get Started Button */}
            <Button
              onClick={handleGetStarted}
              sx={{
                background: 'linear-gradient(90deg, #7573D6 0%, #0060C7 100%)',
                borderRadius: '5px',
                padding: '12px 200px',
                '&:hover': {
                  background: 'linear-gradient(90deg, #8583E6 0%, #1070D7 100%)',
                  transform: 'scale(1.05)',
                },
                transition: 'all 0.2s ease',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ 
                color: 'white',
                fontSize: getResponsiveSize('14px', '16px', '18px', '20px'),
              }}>
                Let's Get Started
              </Typography>
              <ArrowForwardIcon sx={{ 
                fontSize: getResponsiveSize('24px', '28px', '32px', '36px'),
                color: 'white'
              }} />
            </Button>
          </Box>
        )}
      </Box>

      {/* Floating Image */}
      <img
        src={imageSrc}
        alt="Visionary Technology"
        style={{
          width: getResponsiveSize('100px', '120px', '140px', '160px'),
          height: getResponsiveSize('80px', '100px', '110px', '130px'),
          position: "absolute",
          top: getResponsiveSize('80px', '100px', '125px', '150px'),
          left: "50%",
          transform: "translateX(-50%)",
          animation: "rollDice 1s infinite alternate",
        }}
      />
    </div>
  );
};

// CSS Animations
const styles = `
@keyframes moveBackground {
  0% {
    transform: translateX(-50%) translateY(-50%);
  }
  100% {
    transform: translateX(50%) translateY(50%);
  }
}
@keyframes loadingBar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes rollDice {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-10px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}
`;

// Append CSS to document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default IntertesialPage;