import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { FaMagic, FaSave } from "react-icons/fa";
import axios from "axios";
import config from "../url_config/urls_config";
import shareprd from "../assets/shareprd.svg";
import robotOutlineprd from "../assets/robot-outlineprd.svg"; // Fixed the hyphen in the variable name
import Header from "../components/Common/Header";
import { ExpertChat } from "../components/RQPage/ExpertChat";
import { InviteCollaborator } from "../components/RQPage/InviteCollaborator";

const BASE_URL = config.getRq_api();
const PRDPage = () => {
  const location = useLocation();
  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  const [editorContent, setEditorContent] = useState(content); // New state for editor content
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [userPrompt, setUserPrompt] = useState("");
  const [selection, setSelection] = useState(null);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);
  const [isRegenerated, setIsRegenerated] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [improveText, setImproveText] = useState("");
  const [selectionCoords, setSelectionCoords] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [inputText, setInputText] = useState(""); // State for input text
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const inputRef = useRef(null); // Create a ref for the input box
  const [selectedHtmlString, setSelectedHtmlString] = useState(""); // New state for selected HTML string
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false); // New state for loading effect
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const room_name = localStorage.getItem("room_name");
  const [isEditing, setIsEditing] = useState(false); // Track editing state
  const [title, setTitle] = useState("");
  const [isExpertChatOpen, setisExpertChatOpen] = useState(false);
  const [isInviteCollab, setisInviteCollab] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      const url = `${BASE_URL}/api/projects/${room_name}`;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include Authorization header
      };

      try {
        // Make an Axios call
        const response = await axios.get(url, { headers });


        if (response.data.prd) {
          const prdContent = response.data.prd;
          setTitle(response.data.name);

          if (!location.state?.isGenerated) {
            setContent(response.data.prd[0].prdContent);
            if (editorRef.current) {
              editorRef.current.setContents(prdContent);
            }
          } else {
            console.log("Using generated PRD content.");
          }
        } else {
          console.log("No PRD content found in the response.");
        }
      } catch (err) {
        console.error("Something went wrong:", err);
      }
    };
    if (room_name) {
      // Ensure room_name is available before making the request
      fetchProject();
    }
  }, [room_name]); // Dependency on room_name, so the effect re-runs when room_name changes

  useEffect(() => {
    setEditorContent(content); // Sync editor content with main content
    setSaveButtonText("save");
  }, [content]);

  const handleEditorChange = (newContent) => {
    setEditorContent(newContent); // Update local editor content
    setContent(newContent); // Update main content
  };

  const handleTextSelect = () => {
    const selection = window.getSelection();
    if (
      selection &&
      selection.rangeCount > 0 &&
      selection.toString().trim() !== ""
    ) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const isInEditor =
        editorRef.current &&
        editorRef.current.core.context.element.wysiwyg.contains(
          range.startContainer
        );

      if (isInEditor && rect.width > 0) {
        setSelectionCoords({
          top: rect.bottom + window.scrollY + 10,
          left: rect.left + window.scrollX,
        });
        setSelection(selection.toString());

        const selectedHtml = range.cloneContents();
        const div = document.createElement("div");
        div.appendChild(selectedHtml);
        const htmlString = div.innerHTML;
        setSelectedHtmlString(htmlString);
        setIsSelectionActive(true);
        setIsVisible(true);
      } else {
        setIsSelectionActive(false);
        setIsVisible(false);
      }
    } else {
      setIsSelectionActive(false);
      setIsVisible(false);
    }
  };

  const handleSelectionChange = () => {
    if (!isSelectionActive) return;

    const selection = window.getSelection();
    if (!selection || selection.toString().trim() === "") {
      setIsSelectionActive(false);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    document.addEventListener("mouseup", handleTextSelect);
    document.addEventListener("scroll", handleTextSelect);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
      document.removeEventListener("mouseup", handleTextSelect);
      document.removeEventListener("scroll", handleTextSelect);
    };
  }, [isSelectionActive]);

  const handleClosePromptModal = () => {
    setIsPromptModalOpen(false);
  };

  const handlePromptSubmit = async () => {
    setIsLoadingPrompt(true);
    setIsPromptModalOpen(false);
    try {
      const response = await axios.post(`${BASE_URL}/api/modify-prd/`, {
        prdContent: content,
        userPrompt: userPrompt,
      });
      setContent(response.data.modifiedPrd);
      if (editorRef.current) {
        editorRef.current.setContents(response.data.modifiedPrd);
      }
      setIsRegenerated(true);
    } catch (error) {
      console.error("Error modifying PRD:", error);
    } finally {
      setIsLoadingPrompt(false);
    }
  };

  const handleSavePRD = async () => {
    if (content === "<p></p>") return; // Prevent saving empty content

    localStorage.setItem("storyForm", "true");
    setSaveButtonText("Saving..."); // Change text to 'Saving...' when the save process starts

    const room_name = localStorage.getItem("room_name");
    if (!room_name) {
      setSaveButtonText("Save");
      return;
    }

    let url = `${BASE_URL}/api/projects/${room_name}/prd/`; // Using the room_name to construct the URL
    const method = "PUT";
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json", // Ensure the content is sent as JSON
    };
    const payload = {
      prd: content,
    };
    try {
      // Perform the API call using fetch
      let response = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(payload), // Send the payload as JSON
      });

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Unknown error occurred");
      }

      // Parse the JSON response
      const data = await response.json();
      // Handle the response data
      if (data.error) {
        console.log("Error saving PRD:", data.error);
      } else {
        console.log("Saved PRD is:", data);
        const savedContent = data.prd.prd;

        if (savedContent) {
          setContent(savedContent);
          if (editorRef.current) {
            editorRef.current.setContents(savedContent);
          }
        } else {
          console.log("No content returned after saving.");
        }

        setSaveButtonText("Saved");
      }
    } catch (err) {
      console.log("Something went wrong:", err);
      setSaveButtonText("Save");
    }
  };

  const LoadingOverlay = ({ message }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div className="text-white text-2xl">{message}</div>
    </div>
  );

  const handleOpenImproveModal = (e) => {
    e.stopPropagation(); // Prevent the click from affecting the text selection
    setIsModalOpen(true);
    setInputText(selection); // Set the input text to the selected content

    // Use setTimeout to ensure the textarea is focused and selected after rendering
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Focus the textarea immediately
        inputRef.current.select(); // Select the text in the textarea
      }
    }, 0); // Delay to allow rendering
  };

  // Add this event listener to the input box
  const handleInputClick = (e) => {
    e.stopPropagation(); // Prevent click from closing the modal
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsLoadingSubmit(true); // Set loading state to true
      handleSubmit(); // Call the submit function
      setIsModalOpen(false); // Close modal after submission
    }
  };

  const handleSubmit = async () => {
    if (!selectedHtmlString) return;
    setIsLoadingSubmit(true);
    setIsVisible(false); // Hide the "Improve" button

    try {
      console.log("Sending selectedHtmlString:", selectedHtmlString);
      const response = await axios.post(`${BASE_URL}/api/expand`, {
        selected_content: selectedHtmlString,
        prd_content: content,
        user_description: improveText,
      });

      console.log("API response:", response.data);

      if (response.data && response.data.updated_content) {
        const updatedContent = content.replace(
          selectedHtmlString,
          response.data.updated_content
        );
        setContent(updatedContent);

        if (editorRef.current) {
          editorRef.current.setContents(updatedContent);
        }
        // Don't set isVisible to true here
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error improving text:", error);
    } finally {
      setIsLoadingSubmit(false);
      // Don't set isVisible to true here
    }
  };

  const handleTitleClick = () => setIsEditing(true);

  const handleTitleBlur = async () => {
    setIsEditing(false);
    await updateTitle();
  };

  const handleTitleChange = (e) => setTitle(e.target.value);

  const handleTitleKeyDown = async (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      await updateTitle();
    }
  };

  const updateTitle = async () => {
    try {
      const response = await axios.put(`${BASE_URL}/api/update_project_name`, {
        room_name: room_name,
        new_name: title,
      });
      console.log("Response:", response.data);
      alert("Project name updated successfully!");
    } catch (error) {
      console.error(
        "Error updating project name:",
        error.response?.data || error.message
      );
      alert("Failed to update project name. Please try again.");
    }
  };

  return (
    <div>
      <Header />

      <div className="flex flex-col lg:flex-row">
        {/* Pass handleSavePRD here */}
        <div className="p-4 bg-white h-full lg:h-screen flex flex-col w-full items-center">
          {isLoadingPrompt && <LoadingOverlay message="Modifying PRD..." />}
          {isLoadingSubmit && <LoadingOverlay message="Improving PRD..." />}

          <div
            className="flex-grow w-full max-w-6xl"
            style={{
              height: "100vh",
              overflowY: "auto", // Enable vertical scrolling
              borderBlockColor: "#F0F5FC",
              border: "none", // Remove any borders from the container
            }}
          >
            <div className="bg-[#F0F5FC] p-2 lg:p-2 m-0 border-b-0">
              <div className="flex flex-col md:flex-row sm:flex-col justify-between items-center w-full px-4 py-2 my-4">
                <div>
                  {isEditing ? (
                    <input
                      type="text"
                      value={title}
                      onChange={handleTitleChange}
                      onBlur={handleTitleBlur}
                      onKeyDown={handleTitleKeyDown}
                      className="font-montserrat text-2xl lg:text-2xl font-bold leading-tight text-left text-blue-600 p-2 border-b-2 border-transparent focus:border-blue-600 focus:outline-none bg-white max-w-40"
                    />
                  ) : (
                    <h1
                      className="font-montserrat text-2xl lg:text-4xl font-bold leading-tight text-left text-blue-600 md:mb-24 sm:mb-24 lg:mb-0 xl:mb-0 p-2 cursor-pointer"
                      onClick={handleTitleClick}
                    >
                      {title}
                    </h1>
                  )}
                </div>

                <div className="flex gap-4 justify-center sm:mt-20 md:mt-20 lg:mt-0 xl:mt-0">
                  <div className="flex gap-6">
                    <div
                      className="flex items-center bg-white border-2 border-indigo-600 p-[0.5rem] w-[10rem] sm:w-[12rem] md:w-[14rem] lg:w-[15rem] justify-center rounded-sm cursor-pointer hover:bg-indigo-50"
                      onClick={() => {
                        setisInviteCollab(true);
                      }}
                    >
                      <img
                        src={shareprd}
                        alt="Share"
                        className="mr-2 w-[1.5rem] h-[1.5rem]"
                      />
                      <span className="text-sm text-black">Share</span>
                    </div>
                    <div
                      className="flex items-center bg-white border-2 border-indigo-600 p-[0.5rem] w-[10rem] sm:w-[12rem] md:w-[14rem] lg:w-[15rem] justify-center rounded-sm cursor-pointer hover:bg-indigo-50"
                      onClick={() => {
                        setisExpertChatOpen(true);
                      }}
                    >
                      <img
                        src={robotOutlineprd}
                        alt="Chat with an Expert"
                        className="mr-2 w-[1.5rem] h-[1.5rem]"
                      />
                      <span className="text-sm text-black">
                        Chat with an Expert
                      </span>
                    </div>{" "}
                    <div
                      className="flex items-center bg-white border-2 border-indigo-600 p-[0.5rem] w-[10rem] sm:w-[12rem] md:w-[14rem] lg:w-[15rem] justify-center rounded-sm cursor-pointer hover:bg-indigo-50"
                      onClick={handleSavePRD}
                    >
                      <FaSave className="mr-3" />
                      <span className="text-sm text-black">
                        {saveButtonText}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SunEditor
              getSunEditorInstance={(sunEditor) => {
                editorRef.current = sunEditor;

                // Configure the editor using built-in methods
                const editorConfig = sunEditor.core.context.element;

                // Set spellcheck and overflow properties
                editorConfig.wysiwyg.setAttribute("spellcheck", "false");
                Object.assign(editorConfig.wysiwyg.style, {
                  overflowY: "auto", // Allow scrolling in the content area
                  maxHeight: "calc(100vh - 50px)", // Restrict the content area height to fit the viewport
                  borderBlockColor: "#F0F5FC",
                });

                // Apply overall toolbar styling
                Object.assign(editorConfig.toolbar.style, {
                  backgroundColor: "#F0F5FC", // Match the toolbar background color
                  display: "flex",
                  justifyContent: "center !important", // Center items horizontally
                  alignItems: "center !important", // Center items vertically
                  padding: "10px", // Same padding as the custom div
                  gap: "10px", // Add gap between toolbar buttons
                  borderBlockColor: "#F0F5FC",
                  border: "none", // Remove border to integrate with the custom div seamlessly
                  marginTop: "0", // Ensure no top margin between the custom div and the toolbar
                  position: "sticky", // Make the toolbar sticky
                  top: "0", // Stick to the top when scrolling
                });
              }}
              setContents={editorContent}
              onChange={handleEditorChange}
              setDefaultStyle="font-family: Roboto; font-size: 20px !important;"
              setOptions={{
                height: "100%",
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["bold", "underline", "italic", "strike"],
                  ["align", "list"],
                  ["fontColor", "hiliteColor"],
                  ["subscript", "superscript"],
                  ["table"],
                  ["image", "video", "link"],
                  ["fullScreen", "preview", "print"],
                ],
                font: ["Arial", "Roboto", "Times New Roman"],
                fontSize: ["8", "10", "12", "14", "16", "18", "20"],
                formats: ["p", "blockquote", "h1", "h2", "h3"],
                resizingBar: false,
                placeholder: "Start writing here...",
                stickyToolbar: "50px",
                maxHeight: "calc(100vh - 50px)", // Adjust the height to fit your screen
                overflowY: "auto", // Allow scrolling when content exceeds maxHeight
              }}
              width="100%"
              onMouseUp={handleTextSelect}
            />
            {/* // Tailwind CSS with custom styles to hide scrollbar */}
            <style jsx>{`
              .sun-editor .se-content {
                @apply overflow-y-auto; /* Enable scrolling with Tailwind */
              }

              .sun-editor .se-content::-webkit-scrollbar {
                display: none; /* Hide scrollbar for Chrome, Safari */
              }

              .sun-editor .se-content {
                -ms-overflow-style: none; /* For IE and Edge */
                scrollbar-width: none; /* For Firefox */
              }
            `}</style>
          </div>

          {isPromptModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out bg-black bg-opacity-70">
              <div className="relative w-full max-w-md p-8 rounded-lg shadow-xl flex flex-col bg-white">
                <button
                  className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 transition-colors duration-300 ease-in-out"
                  onClick={handleClosePromptModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <h2 className="text-xl font-semibold mb-4">
                  Enter your prompt
                </h2>
                <textarea
                  value={userPrompt}
                  onChange={(e) => setUserPrompt(e.target.value)}
                  className="w-full h-32 p-2 border rounded-lg"
                  placeholder="Write your prompt here..."
                />
                <button
                  onClick={handlePromptSubmit}
                  className="mt-4 bg-blue-900 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  disabled={isLoadingPrompt}
                >
                  {isLoadingPrompt ? "Modifying..." : "Submit"}
                </button>
              </div>
            </div>
          )}

          {isVisible && isSelectionActive && !isLoadingSubmit && (
            <div
              style={{
                position: "absolute",
                top: `${selectionCoords?.top}px`,
                left: `${selectionCoords?.left}px`,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                zIndex: 1000,
              }}
            >
              <button
                className="bg-white bg-opacity-30 backdrop-blur-lg border border-gray-300 rounded-lg p-2 shadow-lg hover:bg-opacity-50 transition duration-200 flex items-center gap-2"
                onClick={handleOpenImproveModal}
              >
                <FaMagic className="text-blue-500" />
                Improve
              </button>
            </div>
          )}

          {isModalOpen && (
            <div
              style={{
                position: "absolute",
                top: `${selectionCoords?.top}px`,
                left: `${selectionCoords?.left}px`, // Adjust position for modal
                zIndex: 1000,
                padding: "0", // Remove padding
                margin: "0", // Remove margin
              }}
              onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
            >
              <textarea
                ref={inputRef} // Attach the ref to the textarea
                onChange={(e) => setImproveText(e.target.value)}
                onKeyDown={handleKeyDown} // Handle Enter key
                onClick={handleInputClick}
                placeholder="Describe what you would like to improve..."
                style={{
                  width: "200%", // Full width
                  height: "50px", // Adjust height
                  padding: "10px", // Add padding
                  border: "1px solid #d1d5db", // Light gray border
                  borderRadius: "8px", // Rounded corners
                  boxShadow: "0 2px 10px rgba(0,0,0,0.1)", // Shadow for depth
                  outline: "none", // Remove outline
                  fontSize: "16px", // Modern font size
                  resize: "none", // Disable resizing
                  backgroundColor: "#f9fafb", // Light background color
                  color: "#374151",
                }}
              />
            </div>
          )}

          {isInviteCollab && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="relative">
                <InviteCollaborator onClose={() => setisInviteCollab(false)} />
              </div>
            </div>
          )}
          {isExpertChatOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="relative">
                <ExpertChat onClose={() => setisExpertChatOpen(false)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PRDPage;
