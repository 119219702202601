"use client";
import React from "react";
import { LoadingSVG } from "../button/LoadingSVG.tsx";
import { ChatMessageType } from "../chat/ChatTile.tsx";
import { PlaygroundHeader } from "../playground/PlaygroundHeader.tsx";
import { AgentMultibandAudioVisualizer } from "../visualization/AgentMultibandAudioVisualizer.tsx";
import { useConfig } from "../../hooks/useConfig.tsx";
import { useMultibandTrackVolume } from "../../hooks/useTrackVolume.tsx";
import { TranscriptionTile } from "../../transcriptions/TranscriptionTile.tsx";
import {
  TrackReferenceOrPlaceholder,
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useRemoteParticipants,
  useRoomInfo,
  useTracks,
} from "@livekit/components-react";
import {
  ConnectionState,
  LocalParticipant,
  RoomEvent,
  Track,
} from "livekit-client";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FaComments } from "react-icons/fa";
import "@livekit/components-styles";
import MicAndSpeaker from "../IntertesialPage/Mic_and_speaker";

export interface PlaygroundMeta {
  name: string;
  value: string;
}

export interface PlaygroundProps {
  logo?: ReactNode;
  themeColors: string[];
  onConnect: (connect: boolean, opts?: { token: string; url: string }) => void;
  chat?: any;
  onCloseRoom?: () => void;
}

const headerHeight = 56;
const visualizerHeight = "15vh";
const micControlsHeight = "4vh";
const chatHeight = "calc(81vh - 56px)";

export default function Playground({
  logo,
  themeColors,
  onConnect,
  chat,
  onCloseRoom,
}: PlaygroundProps) {
  const { config, setUserSettings } = useConfig();
  const { name } = useRoomInfo();
  const [transcripts, setTranscripts] = useState<ChatMessageType[]>([]);
  const { localParticipant } = useLocalParticipant();
  const [showChat, setShowChat] = useState(true); // State to toggle chat visibility
  const [currentMessages, setCurrentMessages] = useState<ChatMessageType[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const initializeMedia = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        console.log("Media permissions granted");
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    initializeMedia();
  }, []);

  useEffect(() => {
    // Set isInitialLoad to false after a brief delay
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 1500); // Small delay to ensure the transition triggers

    return () => clearTimeout(timer);
  }, []);

  const participants = useRemoteParticipants({
    updateOnlyOn: [RoomEvent.ParticipantMetadataChanged],
  });
  const agentParticipant = participants.find((p) => p.isAgent);
  const isAgentConnected = agentParticipant !== undefined;

  const roomState = useConnectionState();
  const tracks = useTracks();

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setMicrophoneEnabled(config.settings.inputs.mic);
    }
  }, [config, localParticipant, roomState]);

  let agentAudioTrack: TrackReferenceOrPlaceholder | undefined;
  const aat = tracks.find(
    (trackRef) =>
      trackRef.publication.kind === Track.Kind.Audio &&
      trackRef.participant.isAgent
  );
  if (aat) {
    agentAudioTrack = aat;
  } else if (agentParticipant) {
    agentAudioTrack = {
      participant: agentParticipant,
      source: Track.Source.Microphone,
    };
  }

  const subscribedVolumes = useMultibandTrackVolume(
    agentAudioTrack?.publication?.track,
    5
  );

  const localTracks = tracks.filter(
    ({ participant }) => participant instanceof LocalParticipant
  );
  const localVideoTrack = localTracks.find(
    ({ source }) => source === Track.Source.Camera
  );
  const localMicTrack = localTracks.find(
    ({ source }) => source === Track.Source.Microphone
  );

  const localMultibandVolume = useMultibandTrackVolume(
    localMicTrack?.publication.track,
    20
  );

  const onDataReceived = useCallback(
    (msg: any) => {
      if (msg.topic === "transcription") {
        const decoded = JSON.parse(
          new TextDecoder("utf-8").decode(msg.payload)
        );
        let timestamp = new Date().getTime();
        if ("timestamp" in decoded && decoded.timestamp > 0) {
          timestamp = decoded.timestamp;
        }
        setTranscripts([
          ...transcripts,
          {
            name: "You",
            message: decoded.text,
            timestamp: timestamp,
            isSelf: true,
          },
        ]);
      }
    },
    [transcripts]
  );

  useDataChannel(onDataReceived);

  const audioTileContent = useMemo(() => {

    const disconnectedContent = (
      <div className="flex flex-col items-center justify-center gap-2 text-gray-700 text-center w-full">
        No audio track. Connect to get started.
      </div>
    );

    const waitingContent = (
      <div className="flex flex-col items-center gap-2 text-gray-700 text-center w-full">
        <LoadingSVG />
        Waiting for audio track
      </div>
    );

    const visualizerContent = (
      <div>
        <AgentMultibandAudioVisualizer
          state="speaking"
          barWidth={36}
          minBarHeight={40}
          maxBarHeight={150}
          accentColor={"#0060C7"}
          accentShade={500}
          frequencies={subscribedVolumes}
          borderRadius={20}
          gap={15}
        />
      </div>
    );

    if (roomState === ConnectionState.Disconnected) {
      return disconnectedContent;
    }

    if (!agentAudioTrack) {
      return waitingContent;
    }

    return visualizerContent;
  }, [
    agentAudioTrack,
    "cyan",
    subscribedVolumes,
    roomState,
    showChat, // Add showChat to dependencies
  ]);

  const chatTileContent = useMemo(() => {
    if (agentAudioTrack) {
      return (
        <TranscriptionTile
          agentAudioTrack={agentAudioTrack}
          accentColor={"cyan"}
          onMessagesUpdate={setCurrentMessages}
          chat={{ conversation: Array.isArray(chat) ? chat : [] }}
        />
      );
    }
    return <></>;
  }, ["cyan", agentAudioTrack, chat]);
  

  const autoConnect = () => {
    onConnect(roomState === ConnectionState.Disconnected);
  };

  const handleMicToggle = async (isOn: boolean) => {
    try {
      if (isOn) {
        if (roomState === ConnectionState.Connected) {
          await localParticipant.setMicrophoneEnabled(true);
        } else {
          console.error("Cannot enable microphone: not connected to the room.");
        }
      } else {
        await localParticipant.setMicrophoneEnabled(false);
      }
    } catch (error) {
      console.error("Error toggling LiveKit microphone:", error);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <PlaygroundHeader
        title={config.title}
        logo={logo}
        githubLink={config.github_link}
        height={headerHeight}
        accentColor={"cyan"}
        connectionState={roomState}
        chat={chat}
        onConnectClicked={() =>
          onConnect(roomState === ConnectionState.Disconnected)
        }
        messages={currentMessages} 
        isAgentConnected={isAgentConnected} 
        autoConnect={autoConnect} 
        onCloseRoom={onCloseRoom}
      />
      
      {/* Audio Visualizer Section */}
      <div 
        className={`flex justify-center items-center transition-all duration-1000 ease-in-out`}
        style={{ 
          height: visualizerHeight,
          minHeight: visualizerHeight,
          maxHeight: visualizerHeight,
          marginTop: isInitialLoad ? '30vh' : '0', // Starts from center, moves up
          opacity: isInitialLoad ? 0.3 : 1,
        }}
      >
        {audioTileContent}
      </div>

      {/* Mic and Speaker Controls */}
      <div 
        className="flex justify-center items-center transition-all duration-1000 ease-in-out"
        style={{
          height: micControlsHeight,
          minHeight: micControlsHeight,
          maxHeight: micControlsHeight,
          opacity: isInitialLoad ? 0 : 1,
          transform: isInitialLoad ? 'translateY(20px)' : 'translateY(0)',
        }}
      >
        <div className="w-2/5">
          <MicAndSpeaker 
            getResponsiveSize={(s, m1, m2, l) => m1} 
            externalAudio={false}
            onMicToggle={handleMicToggle}
          />
        </div>
      </div>

      {/* Chat/Transcript Section */}
      <div 
        className="flex justify-center"
        style={{ 
          height: chatHeight,
          minHeight: chatHeight,
          maxHeight: chatHeight,
          overflow: 'hidden'
        }}
      >
        <div className="w-3/5 h-full">
          <div className="h-full flex flex-col">
            <div
              className="flex-1 overflow-y-auto"
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                padding: '1rem',
                height: '100%'
              }}
            >
              {chatTileContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}