import * as React from "react";

function SharedProjectCard({projectName, description}) {
  return (
    <div className="flex flex-col text-xs rounded-none max-w-[191px]">
      <div className="flex flex-col items-start px-2.5 py-3.5 w-full rounded-lg border border-solid bg-white bg-opacity-50 border-zinc-100 shadow-[0px_1px_6px_rgba(182,191,200,0.3)]">
        <div className="text-xs font-medium text-neutral-900">{projectName}</div>
        <div className="self-stretch mt-3 text-neutral-500">
          {description}
        </div>
        <button 
          className="gap-2 self-stretch px-2.5 py-2 mt-3.5 font-bold text-sky-600 bg-white rounded border border-sky-600 border-solid min-h-[26px]"
          tabIndex={0}
          aria-label={`Add Your Thoughts about ${projectName}`}
        >
          Add Your Thoughts
        </button>
      </div>
    </div>
  );
}

export default SharedProjectCard;