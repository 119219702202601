import React from "react";
import IoPerson from "../../assets/IoPerson.svg";
import { ReactComponent as AgentImageComponent } from "../../assets/23587-unscreen 1 1.svg";

type ChatMessageProps = {
  message: string;
  accentColor: string;
  name: string;
  isSelf: boolean;
  hideName?: boolean;
  isLastInGroup?: boolean;
};

export const ChatMessage = ({
  hideName,
  name,
  message,
  isSelf,
  accentColor,
  isLastInGroup = true,
}: ChatMessageProps) => {
  return (
    <div
      className={`flex flex-col gap-1.5 ${isLastInGroup ? 'mb-6' : 'mb-2'} ${
        isSelf ? 'items-end' : 'items-start'
      }`}
    >
      
      <div className="flex flex-row items-start gap-3">
        {!isSelf && (
          <AgentImageComponent
            className="w-10 h-10 rounded-full flex-shrink-0"
          />
        )}
        <div
          className={`flex rounded-lg px-5 py-3 max-w-[600px] break-words shadow-sm ${
            isSelf
              ? 'bg-[#0060C7] text-white rounded-tr-none'
              : 'bg-gray-100 text-[#181818] rounded-tl-none'
          }`}
          style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          <span className="text-lg leading-relaxed whitespace-pre-wrap font-normal overflow-wrap-break-word">
            {message}
          </span>
        </div>
        {isSelf && (
          <img
            src={IoPerson}
            alt="User"
            className="w-10 h-10 rounded-full object-cover flex-shrink-0"
          />
        )}
      </div>
    </div>
  );
};