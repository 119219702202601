import { FaExclamationTriangle } from "react-icons/fa";

const ForbiddenPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-dark-blue text-white">
      <main className="flex flex-1 items-center justify-center">
        <div className="text-center space-y-8">
          <FaExclamationTriangle className="text-6xl mx-auto text-yellow-500" />
          <h1 className="text-5xl font-bold">403 Forbidden</h1>
          <p className="text-xl">
            You do not have the necessary permissions to access this service.
          </p>
          <p className="text-lg">
            Please contact your administrator for further assistance.
          </p>
          <button
            onClick={() => (window.location.href = "/")}
            className="cq-button px-8 py-3 mt-6 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 focus:outline-none"
          >
            Go to Login
          </button>
        </div>
      </main>
    </div>
  );
};

export default ForbiddenPage;
