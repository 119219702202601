import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import registerframe from "../assets/registerframe.jpg";
import config from "../url_config/urls_config";
import axios from "axios";

const BASE_URL = config.getRq_api();

const RegisterPage = () => {
  const [companyname, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [customRole, setCustomRole] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const email = localStorage.getItem("username");
  const name = localStorage.getItem("name");
  const room_name = localStorage.getItem("room_name");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalRole = role === "other" ? customRole : role;

    setIsLoading(true); // Show loader
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/add-user-details/`,
        {
          email: email,
          name: name,
          organisation: companyname,
          role: finalRole,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        navigate(`/intertesial`, {
          state: {
            name: name,
            room_name: room_name,
            chat: { conversation: [] },
          },
        });
      } else {
        setError(response.data.detail || "Failed to add user details");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred during registration");
      setFieldError(error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-[#99B5D4] to-[#DDCFE8] p-5">
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg w-full max-w-4xl overflow-hidden">
        <div className="w-full md:w-1/2 flex flex-col justify-center px-6 md:px-12 py-8">
          <>
            <h1 className="text-3xl text-blue-700 mb-4 font-extrabold font-monst">
              Set Up Your Profile
            </h1>
            <p className="text-gray-500 mb-6 text-xs font-semibold font-robo">
              Tell us a bit about yourself
            </p>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="company"
                placeholder="Company"
                className="border-b-2 ml-1 focus:outline-none focus:border-black mb-4 pb-2 w-full bg-white text-black font-robo italic"
                value={companyname}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              <select
                name="role"
                className="focus:outline-none mb-4 w-full bg-white text-gray-500 border-b-2 pb-2 font-robo italic cursor-pointer"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Your Role
                </option>
                <option value="business_analyst">Business Analyst</option>
                <option value="engineering_manager">Engineering Manager</option>
                <option value="entrepreneur">Entrepreneur</option>
                <option value="marketing_manager">Marketing Manager</option>
                <option value="product_designer">Product Designer</option>
                <option value="product_manager">Product Manager</option>
                <option value="student">Student</option>
                <option value="other">Other</option>
              </select>

              {role === "other" && (
                <input
                  type="text"
                  name="customRole"
                  placeholder="Specify your role"
                  className="border-b-2 ml-1 focus:outline-none focus:border-black mb-4 pb-2 w-full bg-white text-black"
                  value={customRole}
                  onChange={(e) => setCustomRole(e.target.value)}
                  required
                />
              )}

              {fieldError.email && (
                <p className="text-red-500 text-sm">{fieldError.email}</p>
              )}
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

              <button
                type="submit"
                className={`border-solid border-2 border-sky-500 text-black w-full px-6 py-2 rounded font-extrabold ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-500 hover:text-white"
                }`}
                disabled={isLoading}
              >
                {isLoading ? "Hold tight, we're processing..." : "Continue"}
              </button>
            </form>
          </>
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end items-center py-5 m-10">
          <img
            src={registerframe}
            alt="signup"
            className="max-h-96 w-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
