import { FaSkull } from "react-icons/fa";

const NotFoundPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-dark-blue text-white">
      <main className="flex flex-1 items-center justify-center">
        <div className="text-center space-y-8">
          <FaSkull className="text-6xl mx-auto text-red-400" />
          <h1 className="text-5xl font-bold">404 Not Found</h1>
          <p className="text-xl">
            Oops! It seems that the page you're looking for does not exist.
          </p>

          <button
            onClick={() => (window.location.href = "/")}
            className="cq-button px-8 py-3 mt-6 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 focus:outline-none"
          >
            Go to Login
          </button>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
