import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Play, Pause, RotateCcw } from 'lucide-react';

const SlideControls = ({ 
  currentSlide, 
  totalSlides, 
  onNext, 
  onPrev, 
  isPlaying,
  isPaused,
  onStartPresentation,
  onPauseCommentary,
  onResumeCommentary,
  onRestartPresentation
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        onPrev();
      } else if (event.key === 'ArrowRight') {
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onPrev, onNext]);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex justify-between items-center p-4 bg-white rounded-lg shadow-md"
    >
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onPrev}
        disabled={currentSlide === 0}
        className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
      >
        <ChevronLeft className="w-6 h-6" />
      </motion.button>
      <span className="text-xl font-semibold text-gray-800">{`${currentSlide + 1} / ${totalSlides}`}</span>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onNext}
        disabled={currentSlide === totalSlides - 1}
        className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
      >
        <ChevronRight className="w-6 h-6" />
      </motion.button>
    
        <>
          {!isPlaying ? (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onStartPresentation}
              className="p-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-300 ease-in-out ml-4"
            >
              <Play className="w-6 h-6" />
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={isPaused ? onResumeCommentary : onPauseCommentary}
              className="p-2 bg-yellow-600 text-white rounded-full hover:bg-yellow-700 transition duration-300 ease-in-out ml-4"
            >
              {isPaused ? <Play className="w-6 h-6" /> : <Pause className="w-6 h-6" />}
            </motion.button>
          )}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onRestartPresentation}
            className="p-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition duration-300 ease-in-out ml-4"
          >
            <RotateCcw className="w-6 h-6" />
          </motion.button>
        </>

    </motion.div>
  );
};

export default SlideControls;