// src/components/Header.js

import React from "react";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <header className="flex flex-col justify-center px-32 py-3.5 text-2xl font-bold whitespace-nowrap bg-blue-50 max-md:px-5" >
    <div className="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
      <Link to="/dashboard" className="gap-2.5 self-stretch text-black my-auto">RQ</Link>
    </div>
  </header>
  );
};

export default Header;
