import React from 'react';

const DocumentUpload = ({ documentName, documentType }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4 w-2/3 my-4">
      <div className="flex-shrink-0 bg-blue-500 text-white rounded-md w-10 h-10 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      </div>
      <div>
        <h3 className="text-lg font-medium text-gray-900">{documentName}</h3>
        <p className="text-sm text-gray-500">{documentType}</p>
      </div>
    </div>
  );
};

export default DocumentUpload;