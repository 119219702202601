import React from 'react';
import axios from 'axios';
import config from "../../url_config/urls_config.js";
import attach from "../../assets/attach.svg"
const BASE_URL  = config.getRq_api();
const FileUploadIcon = ({ sendChat, style }) => {
  const fileInputRef = React.useRef(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDocumentUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${BASE_URL}/api/upload-document/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const extractedText = response.data.text + " #2345 ";
      sendChat(extractedText); // Send extracted text to chat
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleDocumentUpload(e.target.files[0]);
    }
  };

  return (
    <div>
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        accept=".docx,.pdf"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Visible icon styled as a button */}
      <button onClick={handleIconClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <img 
          src={attach} 
          alt="Attach"
          style={{ width: '24px', height: '24px' }}
        />
      </button>
    </div>
  );
};

export default FileUploadIcon;
