import * as React from "react";
import { ExpertCard } from "./ExpertCard";
import { ConfirmationMessage } from "./ConfirmationMessage";
import config from "../../url_config/urls_config";

const BASE_URL = config.getRq_api();

export const ExpertChat = ({ onClose, projectId, onAction  }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [selectedExpert, setSelectedExpert] = React.useState({ name: '', role: '' });
  const [modalType, setModalType] = React.useState(null);
  const [warning, setWarning] = React.useState(''); // Warning state

  const experts = [
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/b9540dab5af85e092090320ae890ff8ea7ba914e946103996018c0a4622d4bd9?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      name: "John Smith",
      role: "Tech Expert",
      desc: "Lorem ipsum"
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/0bdbf5b381139d7c52254183d4d5788992f8aaf80d6d24fc00a026e0c5bc8b37?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      name: "Sophia Wilson",
      role: "Design Expert",
      desc: "Lorem ipsum"
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/564f17a417e3c72ce92a5465c5bda20feccbf4c0e6e04a694a21b6bde0e1dc75?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&",
      name: "David Lee",
      role: "Product Expert",
      desc: "Lorem ipsum"
    },
  ];

  const handleExpertClick = (name, role) => {
    setSelectedExpert({ name, role });
    setWarning('');
  };

  const handleSchedule = async () => {
    const { name, role } = selectedExpert;
    console.log("name, role",name,role);

    // Validate that an expert is selected
    if (!name || !role) {
      setWarning('Please select an expert before scheduling.');
      return;
    }
    console.log("name, role", name, role);

    try {
        const response = await fetch(`${BASE_URL}/api/expert_request`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            project_id: projectId, // Assuming projectId is available
            expert: {"name":name,"role":role},
          }),
        });
    
        const result = await response.json();
        if (result.status === 200) {
          setShowConfirmation(true);
        }
        console.log('Server Response:', result);
      } catch (error) {
        console.error('Error:', error);
      }
    
  };
  
  const handleCloseConfirmation = () => {
    setShowConfirmation(false); 
    onClose()
    // onClose();  // This will close the confirmation modal
  };

  const handleAction = (type) => {
    setModalType(type);
    onAction(type); 
  };

  const closeModal = () => {
    setModalType(null);
    onClose(); 
  };

  return (
    <>
      {showConfirmation ? (
        <ConfirmationMessage onClose={handleCloseConfirmation} type="Share" projectId= {projectId}
        onAction={handleAction} />
      ) : (
        <div className="flex justify-center items-center min-h-screen p-6 bg-opacity-50">
          <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg relative p-10 text-center">
            {/* Close Button */}
            <button
              onClick={onClose}
              type="button"
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              ✕
            </button>

            {/* Title Section */}
            <h1 className="text-2xl font-semibold text-neutral-900">Chat with an Expert</h1>
            <p className="mt-2 text-base text-neutral-500">Get personalized guidance for your project</p>

            {/* Experts Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-12">
              {experts.map((expert) => (
                <div
                  key={expert.name}
                  className="cursor-pointer"
                  onClick={() => handleExpertClick(expert.name, expert.role)}
                >
                  <ExpertCard
                    imageSrc={expert.imageSrc}
                    name={expert.name}
                    role={expert.role}
                    desc={expert.desc}
                  />
                </div>
              ))}
            </div>


            {/* Warning Message */}
            {warning && (
              <div className="mt-4 text-sm text-red-500">
                {warning}
              </div>
            )}
            
            {/* Schedule Button */}
            <div className="mt-16 mb-12">
              <button
                className="w-4/12 py-3 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-500"
                onClick={handleSchedule}
                aria-label="Schedule a chat"
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
