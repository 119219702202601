import * as React from "react";

export const ActionButton = ({ icon, text, onClick }) => {
  return (
    <div className="flex flex-col flex-none text-center whitespace-nowrap w-auto">
      <button
        onClick={onClick}
        className="flex items-center text-black justify-center gap-3 px-2 sm:px-6 py-3 bg-white rounded border-2 hover:border-0 border-gray-200 transition-shadow hover:bg-gradient-to-r from-[rgba(0,96,199,0.16)] to-[rgba(150,0,255,0.16)] opacity-100 hover:opacity-100 transition-opacity max-md:px-4 min-w-[100px] sm:min-w-[218px] max-w-[110px] sm:max-w-[218px] h-[32px] sm:h-[50px] w-full relative overflow-hidden"
      >
        <span className="absolute inset-0 rounded"></span>
        <div className="relative z-10 flex items-center gap-1 sm:gap-3">
          <img
            loading="lazy"
            src={icon}
            alt=""
            className="object-contain w-3 sm:w-5 h-3 sm:h-5"
          />
          <span className="text-[8px] sm:text-sm font-bold text-neutral-900">{text}</span>
        </div>
      </button>
    </div>
  );
};

