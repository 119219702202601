import React from "react";
import { motion } from "framer-motion";
import { Hexagon } from "lucide-react";
import {
  ChevronRight,
  CheckCircle,
  TrendingUp,
  Users,
  Settings,
  Briefcase,
  Handshake,
  Target,
  Lightbulb,
  Gamepad,
  Rocket
} from "lucide-react";

const Slide = ({ data, template }) => {
  const getTemplateStyles = () => {
    switch (template) {
      case "Modern":
        return "bg-gradient-to-r from-blue-500 to-indigo-600 text-white";
      case "Classic":
        return "bg-gradient-to-r from-gray-100 to-gray-300 text-gray-800";
      case "Minimalist":
        return "bg-white text-gray-800";
      default:
        return "bg-white text-black";
    }
  };

  const icons = [
    { icon: Settings, color: "bg-blue-500" },
    { icon: Briefcase, color: "bg-sky-500" },
    { icon: Handshake, color: "bg-emerald-500" },
    { icon: Target, color: "bg-amber-400" },
    { icon: Lightbulb, color: "bg-orange-500" },
    { icon: Gamepad, color: "bg-rose-500" },
  ];

  const renderContent = () => {
    switch (data.layout) {
      case "coverSlide":
        return (
          <div className="h-full w-full flex">
            {/* Left section with peach background */}
            <div className="w-[45%] bg-[#0096FF] flex items-center justify-center">
              <Hexagon className="w-32 h-32 text-gray-600" strokeWidth={1.5} />
            </div>
          
            {/* Right section with white background */}
            <div className="flex-1 bg-white flex flex-col justify-center items-center text-center">
              <motion.h1
                className="text-5xl font-bold leading-tight text-gray-800"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {data.title}
              </motion.h1>
            </div>
          </div>
        );

      case "quadrantOverview":
        return (
          <div className="h-full w-full">
            {/* Blue banner with title */}
            <div className="bg-blue-600 py-12 mb-12">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {data.title}
              </motion.h2>
            </div>

            {/* Content grid */}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                {data.content.map((item, index) => (
                  <motion.div
                    key={index}
                    className="flex items-start space-x-6 py-6"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.1 * index }}
                  >
                    <div
                      className={`${icons[index].color} p-4 rounded-full shrink-0`}
                    >
                      {icons[index] &&
                        React.createElement(icons[index].icon, {
                          className: "w-8 h-8 text-white",
                        })}
                    </div>
                    <div className="flex-1">
                      <h3 className="text-2xl font-semibold text-gray-800 mb-3">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                        {item.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        );

      case "bulletedList":
        return (
          <div className="h-full w-full flex">
            {/* Blue banner with problem statement */}
            <div className="bg-blue-600">
              <div className="container mx-auto px-4 mt-4">
                <h2 className="text-2xl md:text-5xl font-bold text-left text-white tracking-tight text-left">
                  Problem Statement
                </h2>
              </div>
            </div>

            <div className="container mx-auto px-4 mb-16 py-4">
              {/* Main problem description */}
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="mb-12"
              >
                <h3 className="text-2xl md:text-3xl font-italic mb-6 text-left text-gray-800 ,ms-2">
                  {data.title}
                </h3>
                <p className="text-xl md:text-2xl text-center text-gray-600 leading-relaxed max-w-3xl mx-auto">
                  {data.description}
                </p>
              </motion.div>

              {/* Four square cards */}
              <div className="container mx-auto px-4 my-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {data.content.slice(0, 3).map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 + index * 0.1 }}
                      className="relative p-8 rounded-2xl border-2 border-[#ff4d6d]"
                    >
                      <div className="flex flex-col items-center text-center space-y-4">
                        <div className="p-4">
                          <div className="w-12 h-12 rounded-full bg-gradient-to-r from-pink-500 to-red-500 flex items-center justify-center">
                            <span className="text-white font-bold text-xl">
                              ✓
                            </span>
                          </div>
                        </div>
                        <h3 className="text-2xl font-bold text-black">
                          {item.title}
                        </h3>
                        <p className="text-gray-600 text-lg">
                          {item.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case "splitContent":
        return (
          <div className="h-full w-full">
            {/* Blue banner */}
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Solution Overview
              </motion.h2>
            </div>

            {/* Main content */}
            <div className="container mx-auto px-4">
              <div className="relative">
                {/* Background decoration */}
                <div className="absolute right-0 top-0 w-1/3 h-full bg-indigo-600 rounded-r-[80px] opacity-10" />

                {/* Content */}
                <div className="relative">
                  {data.content.slice(0, 4).map((item, index) => (
                    <motion.div
                      key={index}
                      className="mb-8 bg-white rounded-2xl shadow-lg p-5 max-w-3xl mr-auto"
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.1 * index }}
                    >
                      <div className="flex items-start gap-6">
                        <div
                          className={`${
                            icons[index]?.color || "bg-blue-100 text-blue-600"
                          } p-4 rounded-2xl`}
                        >
                          {icons[index]
                            ? React.createElement(icons[index].icon, {
                                className: "w-6 h-6",
                              })
                            : null}
                        </div>
                        <div>
                          <h4 className="text-xl font-semibold text-gray-800 mb-2">
                            {item.title}
                          </h4>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case "profileCard":
        return (
          <div className="h-full w-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                User Persona
              </motion.h2>
            </div>
            <div className="flex flex-col md:flex-row gap-8 items-center justify-center flex-grow px-12 rounded-xl">
              <motion.div
                className="md:w-1/3 max-w-sm"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="bg-white bg-opacity-90 p-8 rounded-2xl shadow-xl text-center transform hover:scale-105 transition-transform duration-300 border-2	 border-blue-600">
                  <div className="w-32 h-32 mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full mb-6 flex items-center justify-center shadow-md">
                    <Users className="w-16 h-16 text-white" />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-gray-900">
                    {data.content[0].description}
                  </h3>
                  <p className="text-lg mb-4 text-gray-700">
                    {data.content[1].description}
                  </p>
                </div>
              </motion.div>

              <div className="md:w-2/3 max-w-2xl space-y-4 py-2">
                {data.content.map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg transform hover:translate-y-1 transition-all duration-300"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * (index + 1) }}
                  >
                    <h4 className="text-xl font-semibold mb-2 text-gray-800">
                      {item.title}
                    </h4>
                    <p className="text-gray-600">{item.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        );

      case "iconGrid":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Key Features
              </motion.h2>
            </div>
                        <div className="px-4 sm:px-8 lg:px-16">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 flex-grow py-4">
                {data.content.map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-white bg-opacity-90 p-6 rounded-xl shadow-lg flex flex-col items-center text-center border-2 border-blue-600"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.1 * index }}
                  >
                    <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                      <CheckCircle className="w-8 h-8 text-white" />
                    </div>
                    <h3 className="text-2xl font-bold mb-4 text-gray-800">
                      {item.title}
                    </h3>
                    <p className="text-lg text-gray-800">{item.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        );

      case "processFlow":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                User Journey
              </motion.h2>
            </div>
            <div className="container mx-auto px-4 relative mt-16">
  {/* Content Section */}
  <div className="flex flex-wrap justify-center gap-14 mt-4">
    {data.content.map((item, index) => (
      <motion.div
        key={index}
        className="relative"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.1 * index }}
      >
        {/* Circle Card */}
        <div className="w-72 h-72 bg-white rounded-full p-8 shadow-lg hover:shadow-xl transition-shadow flex flex-col justify-center items-center text-center border border-blue-600 ">
          {/* Icon Above Title */}
          <div className="mb-4">
            <Rocket className="w-8 h-8 text-blue-500" /> {/* Icon from lucide-react */}
          </div>
          
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">
            {item.title}
          </h3>
          <p className="text-gray-600 text-base leading-relaxed">
            {item.description}
          </p>
        </div>

        {/* Chevron Icon */}
        {index < data.content.length - 1 && (
          <div className="absolute -right-10 top-1/2 transform -translate-y-1/2 z-10">
            <ChevronRight className="w-8 h-8 text-blue-400" />
          </div>
        )}
      </motion.div>
    ))}
  </div>
</div>
          </div>
        );

      case "timeline":
        return (
          <div className="w-full h-full">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Project Timeline
              </motion.h2>
            </div>
            <div className="flex flex-col p-6">
              {data.content.map((item, index) => (
                <motion.div
                  key={index}
                  className="flex mb-8"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <div className="w-1/4 pr-4 flex flex-col items-center">
                    <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold mb-2">
                      {index + 1}
                    </div>
                    <h3 className="text-xl font-bold text-center text-gray-800">
                      {item.title}
                    </h3>
                  </div>
                  <div className="w-3/4 bg-white bg-opacity-90 p-6 rounded-lg shadow-lg border border-blue-600">
                    <p className="text-lg text-gray-800">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        );

      case "metricCards":
        return (
          <div className="w-full h-ful">
            <div className="bg-blue-600 py-10">
              <motion.h2
                className="text-4xl md:text-5xl font-bold text-center text-white"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Success Metrics
              </motion.h2>
            </div>
            <div className="grid grid-cols-2 gap-8 flex-grow p-4">
              {data.content.map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white bg-opacity-90 p-6 rounded-xl shadow-lg flex flex-col items-center text-center border border-blue-600"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                    <TrendingUp className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-gray-800">
                    {item.title}
                  </h3>
                  <p className="text-2xl font-bold text-gray-600">
                    {item.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="h-full">
      <div className="w-full h-full  mx-auto bg-white bg-opacity-90 backdrop-blur-lg rounded-xl shadow-2xl overflow-hidden">
        {renderContent()}
      </div>
    </div>
  );
};

export default Slide;
