import React from 'react';
import config from "../../../url_config/urls_config";
import { FcGoogle } from "react-icons/fc";
const BASE_URL  = config.getRq_api();
const GoogleLoginButton = ({ onLogin, buttonText }) => {
  const handleClick = () => {
    window.location.href = `${BASE_URL}/auth/google/login`; // Adjust this URL based on your backend setup
  };

  return (
    <button
      onClick={handleClick}
      className="border-solid border-2 border-sky-500 text-black w-full px-6 py-2 rounded hover:bg-blue-500 hover:text-white flex items-center justify-center"
     >
      <FcGoogle className="mr-2" />
      <span className='text-logingoogle font-robo'>{buttonText}</span>
    </button>
  );
};

export default GoogleLoginButton;
